import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { fetchImage, fetchLogoImage } from '../features/customer/customersSlice'
import { getUserDetails, reset } from '../features/auth/authSlice'
import { customToast } from '../components/CustomToast'
import { Modal } from '../components/Modal'

const MyQRcode = () => {
  const { user, userDetails, isSuccess, isLoading, message } = useSelector((state) => state.auth)
  const { imageUrl } = useSelector((state) => state.customers)
  const dispatch = useDispatch()
  const [showCustomize, setShowCustomize] = useState(false)
  const [isHowItWorksOpen, setIsHowItWorksOpen] = useState(false)

  // Reset state on success, show toast and reset the auth state
  useEffect(() => {
    if (isSuccess) {
      customToast.success(message.message)
      dispatch(reset())
    }
  }, [isSuccess, message, dispatch])

  // Fetch user details on mount
  useEffect(() => {
    dispatch(getUserDetails())
  }, [dispatch])

  // Fetch QR Code image when user details are loaded
  useEffect(() => {
    if (userDetails && userDetails.qr_code) {
      dispatch(fetchImage(userDetails.qr_code))
    }
  }, [dispatch, userDetails])

  // Fetch logo image if available (functionality unchanged)
  useEffect(() => {
    if (userDetails?.logo) {
      dispatch(fetchLogoImage(userDetails.logo))
    }
  }, [userDetails, dispatch])

  const howItWorksContent = (
    <>
      <h3 className='font-bold text-lg mt-6 mb-4'>Understanding Your QR Code System</h3>
      <p className='mb-4 text-left'>
        Your unique QR code is the gateway to growing your loyalty program. It provides a seamless way for customers to join your campaigns, requiring
        minimal effort from your staff.
      </p>

      <h4 className='font-bold mt-4 mb-2 flex items-center'>
        <span className='mr-2'>🎯</span>
        <span>Customer Acquisition Process</span>
      </h4>
      <ul className='list-disc pl-6 mb-4'>
        <li className='text-left mb-2'>Existing app users can instantly join by scanning your QR code</li>
        <li className='text-left mb-2'>New customers are guided to download the app when they scan</li>
        <li className='text-left mb-2'>Zero manual data entry needed - everything is automated</li>
        <li className='text-left mb-2'>Customers can browse and join your available campaigns</li>
      </ul>

      <h4 className='font-bold mt-4 mb-2 flex items-center'>
        <span className='mr-2'>💼</span>
        <span>Business Benefits</span>
      </h4>
      <ul className='list-disc pl-6 mb-4'>
        <li className='text-left mb-2'>Reduce operational costs - no physical loyalty cards needed</li>
        <li className='text-left mb-2'>Save staff time with automated enrollment</li>
        <li className='text-left mb-2'>Track customer engagement in real-time</li>
        <li className='text-left mb-2'>Access detailed analytics and customer insights</li>
        <li className='text-left mb-2'>Eliminate paper waste and storage needs</li>
      </ul>

      <h4 className='font-bold mt-4 mb-2 flex items-center'>
        <span className='mr-2'>📋</span>
        <span>Implementation Guide</span>
      </h4>
      <ul className='list-disc pl-6 mb-4'>
        <li className='text-left mb-2'>Place QR codes at high-visibility points (counter, entrance, tables)</li>
        <li className='text-left mb-2'>Print on receipts and marketing materials</li>
        <li className='text-left mb-2'>Add to menus, price lists, or service catalogs</li>
        <li className='text-left mb-2'>Include in email signatures and social media profiles</li>
      </ul>

      <h4 className='font-bold mt-4 mb-2 flex items-center'>
        <span className='mr-2'>🚀</span>
        <span>Best Practices</span>
      </h4>
      <ul className='list-disc pl-6 mb-4'>
        <li className='text-left mb-2'>Brief your staff on how to explain the benefits to customers</li>
        <li className='text-left mb-2'>Consider offering first-visit incentives to boost sign-ups</li>
        <li className='text-left mb-2'>Monitor analytics to optimize placement and promotion</li>
        <li className='text-left mb-2'>Use table tents or standees for better visibility</li>
      </ul>

      <h4 className='font-bold mt-4 mb-2 flex items-center'>
        <span className='mr-2'>⚡</span>
        <span>Quick Tips for Staff Training</span>
      </h4>
      <ul className='list-disc pl-6 mb-4'>
        <li className='text-left mb-2'>Show staff how to guide customers through their first scan</li>
        <li className='text-left mb-2'>Prepare simple responses to common customer questions</li>
        <li className='text-left mb-2'>Emphasize the simplicity and benefits of digital loyalty</li>
        <li className='text-left mb-2'>Ensure staff know how to troubleshoot basic issues</li>
      </ul>

      <p className='mt-6 font-bold text-left'>
        Your QR code is more than just a link - it's a powerful tool for building your digital loyalty community. Position it strategically and train
        your team effectively to maximize its potential!
      </p>
    </>
  )

  if (!user) {
    return null
  }

  return (
    <>
      <Helmet>
        <title>My QR Code | Loyalty Club PLC</title>
      </Helmet>

      {!showCustomize && (
        <div className='relative w-screen left-1/2 -translate-x-1/2 bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 p-8 mb-8 overflow-hidden shadow-2xl rounded-b-3xl -mt-[30px]'>
          {/* Animated Scanning Effect */}
          <div className='absolute inset-0 bg-gradient-to-b from-white/10 to-transparent opacity-30 animate-scan-beam' />

          {/* Digital Pattern Overlay */}
          <div className='absolute inset-0 opacity-20 mix-blend-overlay bg-[linear-gradient(45deg, transparent 48%, #fff 50%, transparent 52%)] bg-[length:8px_8px]' />

          <div className='relative z-10 max-w-6xl mx-auto text-center'>
            <h1 className='text-4xl md:text-5xl font-bold text-white mb-4 animate-fade-in-up'>
              {user.businessName}{' '}
              <span className='inline-block bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-amber-400'>QR Code</span>
            </h1>
            <p className='text-lg md:text-xl text-white/90 mb-6 max-w-2xl mx-auto'>
              Share this unique QR code with your customers to seamlessly join your campaigns and boost engagement.
            </p>
            <div className='ml-10 mr-10 mt-10 mb-8'>
              {imageUrl ? (
                <img
                  className='mx-auto w-[250px] h-[250px] rounded-2xl border-8 border-orange-500 shadow-2xl'
                  src={imageUrl}
                  alt='Business QR Code'
                />
              ) : (
                <div className='flex justify-center items-center'>
                  <svg className='animate-spin h-16 w-16 text-orange-500' viewBox='0 0 24 24'>
                    <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
                    <path className='opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'></path>
                  </svg>
                </div>
              )}
            </div>
            {/* CTA Buttons */}
            <div className='flex flex-col md:flex-row justify-center space-y-2 md:space-y-0 md:space-x-4'>
              <a href='/campaigns' className='btn btn-primary btn-sm md:btn-lg transform hover:scale-105 transition-all text-sm md:text-base px-4'>
                📊 Manage Campaigns
              </a>
              <a
                href='/marketingresources'
                className='btn btn-primary btn-sm md:btn-lg transform hover:scale-105 transition-all text-sm md:text-base px-4'>
                🎨 Create a Campaign Flyer
              </a>
              <button
                onClick={() => setIsHowItWorksOpen(true)}
                className='btn btn-secondary btn-sm md:btn-lg transform hover:scale-105 transition-all text-sm md:text-base px-4'>
                💡 How It Works
              </button>
            </div>
          </div>

          {/* Keyframe Animations and Custom Styles */}
          <style>{`
            @keyframes scan-beam {
              0% { transform: translateY(-100%); opacity: 0; }
              50% { opacity: 0.4; }
              100% { transform: translateY(100%); opacity: 0; }
            }
            .animate-scan-beam {
              animation: scan-beam 6s ease-in-out infinite;
            }
            .animate-fade-in-up {
              animation: fade-in-up 0.8s ease-out forwards;
            }
          `}</style>
        </div>
      )}

      <Modal isOpen={isHowItWorksOpen} onClose={() => setIsHowItWorksOpen(false)} title='How to Use Your QR Code'>
        {howItWorksContent}
      </Modal>
    </>
  )
}

export default MyQRcode
