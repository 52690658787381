import { useState, useEffect } from 'react'
import { CountryDropdown } from 'react-country-region-selector'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetails } from '../features/auth/authSlice'
import { customToast } from './CustomToast'
import { reset } from '../features/payments/paymentsSlice'

const SubscriptionPaymentForm = ({ product, selectedVariation, onPaymentSuccess, onCancel, modalContentId }) => {
  const dispatch = useDispatch()
  const [payments, setPayments] = useState(null)
  const [card, setCard] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState('')
  const { userDetails, user } = useSelector((state) => state.auth)
  const { isSuccess, isError, message, isLoading, subscriptionDetails } = useSelector((state) => state.payments)

  const [cardholderDetails, setCardholderDetails] = useState({
    cardholderName: '',
    addressLine1: '',
    addressLine2: '',
    locality: '',
    administrativeDistrictLevel1: '',
    country: 'GB',
  })

  const { REACT_APP_SQUARE_APPLICATION_ID, REACT_APP_SQUARE_LOCATION_ID } = process.env

  const [paymentError, setPaymentError] = useState(null)
  const [shouldReinitializeCard, setShouldReinitializeCard] = useState(false)

  useEffect(() => {
    let mounted = true

    const loadSquareSDK = async () => {
      try {
        // Clean up any existing Square scripts
        const existingScript = document.querySelector('script[src*="square.js"]')
        if (existingScript) {
          existingScript.remove()
        }

        const script = document.createElement('script')
        script.src = process.env.REACT_APP_SQUARE_SDK_URL
        script.async = true

        script.onload = async () => {
          if (!mounted) return

          try {
            const payments = window.Square.payments(REACT_APP_SQUARE_APPLICATION_ID, REACT_APP_SQUARE_LOCATION_ID)
            setPayments(payments)

            const modalContent = document.getElementById(modalContentId)
            if (!modalContent) {
              throw new Error('Modal content element not found')
            }

            const cardInstance = await payments.card()
            await cardInstance.attach('#card-container')
            setCard(cardInstance)
            setShouldReinitializeCard(false)
          } catch (error) {
            console.error('Error initializing Square card:', error)
            customToast.error('Error initializing payment form. Please try again.')
          }
        }

        document.body.appendChild(script)
      } catch (error) {
        console.error('Error loading Square SDK:', error)
        customToast.error('Error loading payment form. Please try again.')
      }
    }

    if (!card || shouldReinitializeCard) {
      loadSquareSDK()
    }

    // Cleanup function
    return () => {
      mounted = false
      if (card) {
        card.destroy()
      }
      const existingIframe = document.querySelector('iframe[name^="square-"]')
      if (existingIframe) {
        existingIframe.remove()
      }
    }
  }, [shouldReinitializeCard, modalContentId, REACT_APP_SQUARE_APPLICATION_ID, REACT_APP_SQUARE_LOCATION_ID])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setCardholderDetails((prev) => ({ ...prev, [name]: value }))
  }

  const handlePayment = async (e) => {
    e.preventDefault()
    setIsProcessing(true)
    setPaymentError(null)
    setPaymentStatus('')

    try {
      if (!card || !payments) {
        throw new Error('Card payment method not initialized')
      }

      const tokenResult = await card.tokenize()
      console.log('Tokenization Result:', tokenResult)
      if (tokenResult.status === 'OK') {
        // Get postal code from the tokenization result
        const postalCode = tokenResult.details?.billing?.postalCode

        const verificationDetails = {
          billingContact: {
            givenName: cardholderDetails.cardholderName.split(' ')[0],
            familyName: cardholderDetails.cardholderName.split(' ').slice(1).join(' '),
            email: userDetails.email,
            address: {
              addressLine1: cardholderDetails.addressLine1,
              addressLine2: cardholderDetails.addressLine2 || undefined,
              locality: cardholderDetails.locality,
              administrativeDistrictLevel1: cardholderDetails.administrativeDistrictLevel1,
              postal_code: postalCode,
              country: cardholderDetails.country,
            },
          },
          currencyCode: user.subscription.currency.toUpperCase(),
          intent: 'STORE',
        }
        console.log('💳 💰 ~ handlePayment ~ verificationDetails:', verificationDetails)
        console.log('Starting buyer verification...')

        const verificationToken = await payments.verifyBuyer(tokenResult.token, verificationDetails)
        console.log('🚀 ~ handlePayment ~ verificationToken:', verificationToken)
        const squareCustomerId = user.subscription.squareCustomerID
        const addressDetails = user?.userDetails?.address
        console.log('🚀 ~ handlePayment ~ addressDetails:', addressDetails)
        const customerName = user.name
        const customerId = user._id

        const paymentData = {
          token: tokenResult.token,
          squareCustomerId: squareCustomerId,
          productId: selectedVariation.id,
          productData: product,
          addressDetails: addressDetails,
          customerId,
          cardholderName: cardholderDetails.cardholderName,
          billingAddress: {
            addressLine1: cardholderDetails.addressLine1,
            addressLine2: cardholderDetails.addressLine2,
            locality: cardholderDetails.locality,
            administrativeDistrictLevel1: cardholderDetails.administrativeDistrictLevel1,
            country: cardholderDetails.country,
          },
          customerName: customerName,
          verificationToken: verificationToken,

          cardholderDetails: cardholderDetails,
        }
        console.log('Payment data being sent:', paymentData)

        try {
          // Wait for payment processing
          await onPaymentSuccess(paymentData)

          // Wait for 2 seconds to allow backend processing
          await new Promise((resolve) => setTimeout(resolve, 2000))

          // Now fetch updated user details
          await dispatch(getUserDetails())
          setPaymentStatus('Payment successful!')
        } catch (error) {
          setPaymentError(error.message || 'Payment processing failed. Please try again.')
          throw error
        }
      } else {
        throw new Error(tokenResult.errors[0].message)
      }
    } catch (error) {
      console.error('Payment error:', error)
      setPaymentError(error.message || 'Payment processing failed. Please try again.')
      setPaymentStatus('')

      // Force reinitialization
      setCard(null)
      setShouldReinitializeCard(true)
    } finally {
      setIsProcessing(false)
    }
  }

  useEffect(() => {
    console.log('there is an error ')
    if (isError && message) {
      const errorMessage = message
      customToast.error(errorMessage)
    }
    dispatch(reset())
  }, [isError, message])

  return (
    <div className='max-w-md mx-auto'>
      <h2 className='text-2xl font-bold mb-6'>Payment Details</h2>

      {paymentError && <div className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4'>{paymentError}</div>}

      {isProcessing && (
        <div className='flex flex-col items-center justify-center space-y-4 mb-4'>
          <div className='w-12 h-12 border-4 border-primary border-t-transparent rounded-full animate-spin'></div>
          <p className='text-gray-500'>Processing payment...</p>
        </div>
      )}

      <form onSubmit={handlePayment} className={`space-y-4 ${isProcessing ? 'opacity-60 pointer-events-none' : ''}`}>
        <div className='form-group relative mb-2'>
          <input
            type='text'
            className='global-input peer'
            id='cardholderName'
            name='cardholderName'
            value={cardholderDetails.cardholderName}
            onChange={handleInputChange}
            required
            placeholder=' '
          />
          <label
            htmlFor='cardholderName'
            className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
            Cardholder Name
          </label>
        </div>

        <div className='form-group relative mb-2'>
          <input
            type='text'
            className='global-input peer'
            id='addressLine1'
            name='addressLine1'
            value={cardholderDetails.addressLine1}
            onChange={handleInputChange}
            required
            placeholder=' '
          />
          <label
            htmlFor='addressLine1'
            className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
            Address Line 1
          </label>
        </div>

        <div className='form-group relative mb-2'>
          <input
            type='text'
            className='global-input peer'
            id='locality'
            name='locality'
            value={cardholderDetails.locality}
            onChange={handleInputChange}
            required
            placeholder=' '
          />
          <label
            htmlFor='locality'
            className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
            City
          </label>
        </div>

        <div className='relative mb-2'>
          <CountryDropdown
            value={cardholderDetails.country}
            onChange={(val) => handleInputChange({ target: { name: 'country', value: val } })}
            priorityOptions={['GB']}
            valueType='short'
            classes='select select-bordered w-full'
          />
          <label
            htmlFor='country'
            className={`absolute left-3 text-gray-500 pointer-events-none text-sm px-1 transition-all
              ${cardholderDetails.country ? '-top-2.5' : 'top-f1'}`}>
            Your Country
          </label>
        </div>

        <div id='card-container' className='mb-4'></div>

        <div className='flex gap-3'>
          <button type='button' onClick={onCancel} className='btn btn-ghost flex-1' disabled={isProcessing}>
            Cancel
          </button>
          <button type='submit' disabled={isProcessing} className='btn btn-primary flex-1'>
            Complete Payment
          </button>
        </div>
      </form>
    </div>
  )
}

export default SubscriptionPaymentForm
