import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { customToast } from '../components/CustomToast'
import { useSelector, useDispatch } from 'react-redux'
import { fetchCampaignStamps, resetStamps, reset as resetCampaigns } from '../features/campaign/campaignsSlice'
import StampItem from '../components/StampItem'
import Spinner from '../components/Spinner'
import { revokeStamp, reset } from '../features/customer/customersSlice'
import BackButton from '../components/BackButton'

const OrderDetails = () => {
  const dispatch = useDispatch()
  const { orderNumber } = useParams()
  const [selectedStampForRevocation, setSelectedStampForRevocation] = useState(null)
  const { user } = useSelector((state) => state.auth)
  const { stamps, isLoading, isError, message, stampsIsLoading } = useSelector((state) => state.campaigns)
  const { message: customersMessage, isError: customersIsError, isSuccess } = useSelector((state) => state.customers)

  useEffect(() => {
    if (isError) {
      customToast.error(message)
    }
    if (isSuccess) {
      customToast.success(customersMessage)
      dispatch(resetCampaigns())
    }
    if (customersIsError) {
      customToast.error(customersMessage)
    }
    dispatch(reset())
  }, [isError, message, dispatch, customersIsError, customersMessage, isSuccess])

  useEffect(() => {
    dispatch(fetchCampaignStamps({ orderNumber }))

    // Cleanup function
    return () => {
      dispatch(resetStamps())
    }
  }, [])

  const handleRevokeClick = (stamp) => {
    setSelectedStampForRevocation(stamp)
    document.getElementById('my-modal-7').checked = true
  }

  const handleRevoke = () => {
    dispatch(revokeStamp(selectedStampForRevocation._id)).then(() => {
      dispatch(fetchCampaignStamps({ orderNumber }))
    })
    // Close the modal here to ensure it happens after the asynchronous operations are complete
    document.getElementById('my-modal-7').checked = false
  }

  // if (isLoading || stampsIsLoading) {
  //   return <Spinner />
  // }

  if (isError) {
    return <div>{message}</div>
  }

  const orderValue = stamps.length > 0 ? stamps[0].orderValue : null

  return (
    <div className='pb-28'>
      <div className='flex'>
        <BackButton />
      </div>
      {orderValue && (
        <p className='text-2xl mb-4'>
          Order Value: {user.currency}
          {orderValue}
        </p>
      )}

      {/* Put this part before </body> tag */}
      <input type='checkbox' id='my-modal-7' className='modal-toggle' />

      {/* Modal */}
      <div className='modal modal-bottom sm:modal-middle'>
        <div className='modal-box'>
          {/* Check if a stamp has been selected for revocation */}
          {selectedStampForRevocation && (
            <>
              <h3 className='font-bold text-lg'>Warning! You're about to revoke a stamp for {selectedStampForRevocation.customerName}</h3>
              <p className='py-4'>Are you sure that you would like to revoke this stamp?</p>
              <div className='modal-action flex justify-between'>
                <label
                  onClick={() => {
                    // Call your revoke function here
                    handleRevoke(selectedStampForRevocation._id)
                  }}
                  className='btn btn-error'>
                  Confirm Revoke Stamp
                </label>
                <label htmlFor='my-modal-7' className='btn'>
                  Cancel
                </label>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Stamps Table */}
      <h1 className='font-bold text-lg'>Stamps in this order</h1>
      <div className='overflow-x-auto w-full pb-20'>
        <table className='table w-full shadow-lg'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Customer Name</th>
              <th>Campaign Name</th>
              <th>Server Details</th>
              <th>Stamp Status</th>
            </tr>
          </thead>
          <tbody>
            {stamps.map((stamp) => (
              <StampItem key={stamp._id} stamp={stamp} user={user} hideStampCount={true} onRevokeClick={handleRevokeClick} />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>Date</th>
              <th>Customer Name</th>
              <th>Campaign Name</th>
              <th>Server Details</th>
              <th>Stamp Status</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

export default OrderDetails
