import { useSelector, useDispatch } from 'react-redux'
import { customToast } from '../components/CustomToast'
import { useEffect, useState, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import Spinner from '../components/Spinner'
import StampItem from '../components/StampItem'
import { getUserDetails, fetchImage } from '../features/auth/authSlice'
import { MdPeople } from 'react-icons/md'
import { AiFillGift } from 'react-icons/ai'
import { FaStamp } from 'react-icons/fa'
import { TbRubberStampOff } from 'react-icons/tb'
import { PiHandCoinsBold } from 'react-icons/pi'
import DateRangePicker from '../components/DateRangePicker'
import { revokeStamp, reset } from '../features/customer/customersSlice'
import Pagination from '../components/Pagination'
import SkeletonRow from '../components/SkeletonRow'

import {
  fetchCampaign,
  fetchCompletedCampaignPercentage,
  fetchCampaignStamps,
  fetchCampaignStampsCount,
  fetchStampCount,
} from '../features/campaign/campaignsSlice'
import { fetchServers } from '../features/server/serversSlice'
import BackButton from '../components/BackButton'

const getFormattedDate = (date) => {
  // This function will format the date as a string; adjust the formatting as needed
  return date.toISOString()
}

const Campaign = () => {
  const { servers } = useSelector((state) => state.servers)
  const { user, userDetails } = useSelector((state) => state.auth)
  const { isLoading, isSuccess, isError, message, campaign, stamps, campaignStampsCount, stampsIsLoading } = useSelector((state) => state.campaigns)
  const { isError: customersIsError, isSuccess: customersIsSuccess, message: customersMessage } = useSelector((state) => state.customers)
  const [selectedStampForRevocation, setSelectedStampForRevocation] = useState(null)

  // Calculate the dates for the last 24 hours
  const endDate = new Date()
  const startDate = new Date(new Date().setDate(endDate.getDate() - 1))

  const [formattedStartDate, setFormattedStartDate] = useState(getFormattedDate(startDate))
  const [formattedEndDate, setFormattedEndDate] = useState(getFormattedDate(endDate))

  //pagination
  const [currentPage, setCurrentPage] = useState(1)
  const stampsPerPage = 10
  const startIndex = (currentPage - 1) * stampsPerPage
  const endIndex = startIndex + stampsPerPage

  const [selectedServer, setSelectedServer] = useState({ _id: null, name: '' })

  //stamp filter
  const [selectedServerName, setSelectedServerName] = useState('')
  const filteredStamps = stamps.filter((stamp) => selectedServer.name === '' || stamp.serverName === selectedServer.name)
  const [isStampRevoked, setIsStampRevoked] = useState(false)
  const [images, setImages] = useState({
    image: null,
  })

  const dispatch = useDispatch()
  const { campaignID } = useParams()

  const onDateRangeSelect = ({ startDate, endDate }) => {
    // Convert startDate to the start of the day (if startDate is provided)
    const formattedStartDate = startDate ? moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null

    // Convert endDate to the end of the day (if endDate is provided)
    const formattedEndDate = endDate ? moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null

    // Replace this with whatever you need to do with the dates
    dispatch(fetchCampaignStamps({ campaignID, startDate: formattedStartDate, endDate: formattedEndDate }))
    dispatch(fetchCampaignStampsCount({ campaignID, startDate: formattedStartDate, endDate: formattedEndDate, serverID: selectedServer._id }))

    if (isStampRevoked) setIsStampRevoked(false)
  }

  useEffect(() => {
    // Check if there is a selected server and a customer ID
    if (selectedServer._id) {
      // Dispatch the action with the new server ID and other necessary parameters
      dispatch(fetchCampaignStampsCount({ campaignID, startDate: formattedStartDate, endDate: formattedEndDate, serverID: selectedServer._id }))
    }
  }, [selectedServer, formattedStartDate, formattedEndDate, dispatch])

  useEffect(() => {
    dispatch(fetchCampaignStamps({ campaignID, startDate: formattedStartDate, endDate: formattedEndDate }))
    dispatch(fetchCampaignStampsCount({ campaignID, startDate: formattedStartDate, endDate: formattedEndDate, serverID: selectedServer._id }))
    if (isStampRevoked) setIsStampRevoked(false)
  }, [isStampRevoked])

  useEffect(() => {
    if (isError) {
      customToast.error(message)
      dispatch(reset())
    }
    if (customersIsError) {
      customToast.error(customersMessage)
      dispatch(reset())
    }
    dispatch(fetchServers())
    dispatch(fetchCampaign(campaignID))
    dispatch(fetchCompletedCampaignPercentage(campaignID))
  }, [dispatch, isError, message, campaignID, customersIsError, customersMessage, stampsIsLoading])

  useEffect(() => {
    if (!userDetails) {
      dispatch(getUserDetails())
    }
  }, [dispatch, userDetails])

  useEffect(() => {
    if (campaign.image) {
      dispatch(fetchImage(campaign.image)).then((res) => {
        setImages((prevState) => ({
          ...prevState,
          image: res.payload,
        }))
      })
    }
  }, [dispatch, campaign.image])

  useEffect(() => {
    setCurrentPage(1)
  }, [selectedServerName])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  function handleServerSelection(event) {
    const value = event.target.value

    if (value === '') {
      setSelectedServer({ _id: null, name: '' })
    } else {
      const [_id, name] = value.split(',')
      setSelectedServer({ _id, name })
    }
  }

  // Create a new array that includes the ClientUser
  const newServers = servers.slice()
  if (user) {
    newServers.push({ _id: user._id, name: user.name })
  }

  const handleRevokeClick = (stamp) => {
    setSelectedStampForRevocation(stamp)
    document.getElementById('my-modal-7').checked = true
  }

  const handleRevoke = () => {
    dispatch(revokeStamp(selectedStampForRevocation._id)).then(() => dispatch(fetchStampCount(campaignID)))
    setIsStampRevoked(true)

    // Close the modal here to ensure it happens after the asynchronous operations are complete
    document.getElementById('my-modal-7').checked = false
  }

  const groupStampsByOrderNumber = (stamps) => {
    const groupedStamps = []

    stamps.forEach((stamp) => {
      const existingGroup = groupedStamps.find((group) => group.orderNumber === stamp.orderNumber)

      if (existingGroup) {
        existingGroup.stamps.push(stamp)
        if (stamp.status === 'revoked') {
          existingGroup.revokedStamps++
        }
      } else {
        groupedStamps.push({
          orderNumber: stamp.orderNumber,
          stamps: [stamp],
          revokedStamps: stamp.status === 'revoked' ? 1 : 0,
        })
      }
    })

    return groupedStamps
  }

  const groupedStamps = groupStampsByOrderNumber(filteredStamps)
  const totalStamps = groupedStamps.length
  const totalPages = Math.ceil(totalStamps / stampsPerPage)

  return (
    <div className='pb-20'>
      <Helmet>
        <title>Campaign Details | Loyalty Club PLC</title>
      </Helmet>
      <div className='flex'>
        <BackButton />
      </div>
      {/* Put this part before </body> tag */}
      <input type='checkbox' id='my-modal-7' className='modal-toggle' />
      {/* Modal */}
      <div className='modal modal-bottom sm:modal-middle'>
        <div className='modal-box'>
          {/* Check if a stamp has been selected for revocation */}
          {selectedStampForRevocation && (
            <>
              <h3 className='font-bold text-lg'>Warning! You're about to revoke a stamp for {selectedStampForRevocation.customerName}</h3>
              <p className='py-4'>Are you sure that you would like to revoke this stamp?</p>
              <div className='modal-action flex justify-between'>
                <label
                  onClick={() => {
                    // Call your revoke function here
                    handleRevoke(selectedStampForRevocation._id)
                  }}
                  className='btn btn-error'>
                  Confirm Revoke Stamp
                </label>
                <label htmlFor='my-modal-7' className='btn'>
                  Cancel
                </label>
              </div>
            </>
          )}
        </div>
      </div>
      {/* {isLoading ? <Spinner /> : ''} */}
      {campaign ? (
        <>
          <div className='flex justify-center items-center'>
            {images && images.image ? <img className='w-40 h-40 mask mask-squircle' src={images.image.image} alt='' /> : ''}
          </div>
          <h1 className={`font-bold text-3xl mt-10 ${campaign.campaignType === 'Spend X Amount To Earn a Stamp' ? 'mb-0' : 'mb-10'}`}>
            Campaign Details for {campaign.campaignName}
          </h1>
          {campaign.campaignType === 'Spend X Amount To Earn a Stamp' && (
            <div className=' flex justify-center text-gray-400  text-left mb-8 text-xl'>
              Spend {campaign.partnerCurrency}
              {campaign.spendPerStamp} for each stamp earned
            </div>
          )}
          {/* Campaign statistics */}

          {/* Statistics cards */}
          <div className='border rounded-xl'>
            <h1 className='font-titillium-web'>LIFETIME CAMPAIGN STATISTICS</h1>
            <div className='grid grid-cols-3 md:grid-cols-5 gap-x-2 md:gap-x-4 '>
              <div className='card w-full bg-base-100 shadow-xl mt-4 p-0 tooltip' data-tip='Lifetime total customers enrolled in the campaign'>
                <div className='card-body flex justify-center items-center text-center'>
                  <h2 className='card-title flex flex-col text-sm md:text-lg'>
                    <span>
                      <MdPeople size='32' />
                    </span>
                    Enrolled Customers
                    <span>{campaign.customersEnrolled}</span>
                  </h2>
                </div>
              </div>
              <div className='card w-full bg-base-100 shadow-xl mt-4 p-0 tooltip' data-tip='Lifetime total rewards claimed by customers'>
                <div className='card-body flex justify-center items-center text-center'>
                  <h2 className='card-title flex flex-col text-sm md:text-lg'>
                    <span>
                      <AiFillGift size='32' />
                    </span>
                    Rewards Claimed
                    <span>{campaign.goalsCompleted}</span>
                  </h2>
                </div>
              </div>
              <div className='card w-full bg-base-100 shadow-xl mt-4 p-0 tooltip' data-tip='Lifetime total stamps issued to customers'>
                <div className='card-body flex justify-center items-center text-center'>
                  <h2 className='card-title flex flex-col text-sm md:text-lg'>
                    <span>
                      <FaStamp size='32' />
                    </span>
                    Stamps Issued
                    <span>{campaign.totalStampsAdministered}</span>
                  </h2>
                </div>
              </div>
              <div className='card w-full bg-base-100 shadow-xl mt-4 p-0 tooltip' data-tip='Lifetime total stamps redeemed for rewards'>
                <div className='card-body flex justify-center items-center text-center'>
                  <h2 className='card-title flex flex-col text-sm md:text-lg'>
                    <span>
                      <TbRubberStampOff size='32' />
                    </span>
                    Stamps Redeemed
                    <span>{campaign.totalStampsRedeemed}</span>
                  </h2>
                </div>
              </div>
              <div className='card w-full bg-base-100 shadow-xl mt-4 p-0 tooltip' data-tip='Percentage of customers that earned a reward'>
                <div className='card-body flex justify-center items-center text-center'>
                  <h2 className='card-title flex flex-col text-sm md:text-lg'>
                    <span>
                      <AiFillGift size='32' />
                    </span>
                    First Reward Rate
                    <span>{campaign.redeemedPercentage}%</span>
                  </h2>
                </div>
              </div>
              <div className='card w-full bg-base-100 shadow-xl mt-4 p-0 tooltip' data-tip='Lifetime total stamps sold for TEDs'>
                <div className='card-body flex justify-center items-center text-center'>
                  <h2 className='card-title flex flex-col text-sm md:text-lg'>
                    <span>
                      <PiHandCoinsBold size='32' />
                    </span>
                    Stamps Sold
                    <span>{campaign.totalStampsSoldForTeds}</span>
                  </h2>
                </div>
              </div>
              <div className='card w-full bg-base-100 shadow-xl mt-4 p-0 tooltip' data-tip='Lifetime total stamps bought back using TEDs'>
                <div className='card-body flex justify-center items-center text-center'>
                  <h2 className='card-title flex flex-col text-sm md:text-lg'>
                    <span>
                      <PiHandCoinsBold size='32' />
                    </span>
                    Stamps Bought Back
                    <span>{campaign.totalStampsBoughtWithTeds}</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className='divider mt-12'></div>
          <h2 className='text-2xl font-bold mb-4'>Campaign Performance Insights</h2>
          {/* Selectors Range */}
          <div className='flex flex-col sm:flex-row justify-between mt-12'>
            {/* Date Range */}

            <DateRangePicker onDateRangeSelect={onDateRangeSelect} />

            {/* Server Name selector */}
            <div className='form-control w-full md:max-w-md ml-0 sm:ml-3'>
              <label className='label '>
                <span className='label-text'>Server Name:</span>
              </label>
              <select
                className='select select-bordered w-full'
                value={selectedServer._id ? `${selectedServer._id},${selectedServer.name}` : ''}
                onChange={handleServerSelection}>
                <option value=''>All Servers</option>
                {newServers.map((server) => (
                  <option key={server._id} value={`${server._id},${server.name}`}>
                    {server.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* Statistics cards */}
          <div className='grid grid-cols-3 gap-2 md:gap-20 lg:gap-28 mb-8'>
            <div className='tooltip' data-tip='Stamps given to customers within the selected date range'>
              <div className='card w-full bg-base-100 shadow-xl mt-4 items-center'>
                <div className='card-body flex-row items-center'>
                  {stampsIsLoading ? (
                    <img className='items-center' src='/images/loadingSpinner.svg' alt='Loading...' />
                  ) : (
                    <h2 className='card-title text-sm md:text-lg flex flex-col'>
                      <span>
                        <FaStamp size='32' />
                      </span>
                      Stamps administered
                      <span>{campaignStampsCount.administeredCount}</span>
                    </h2>
                  )}
                </div>
              </div>
            </div>

            <div className='tooltip' data-tip='Stamps used for rewards within the selected date range'>
              <div className='card w-full bg-base-100 shadow-xl mt-4 items-center'>
                <div className='card-body flex-row items-center text-center'>
                  {stampsIsLoading ? (
                    <img className='items-center' src='/images/loadingSpinner.svg' alt='Loading...' />
                  ) : (
                    <h2 className='card-title text-sm md:text-lg flex flex-col'>
                      <span>
                        <TbRubberStampOff size='32' />
                      </span>
                      Stamps redeemed
                      <span>{campaignStampsCount.redeemedCount}</span>
                    </h2>
                  )}
                </div>
              </div>
            </div>

            <div className='tooltip' data-tip='Rewards given based on redeemed stamps and campaign goal within the selected date range'>
              <div className='card w-full bg-base-100 shadow-xl mt-4 items-center'>
                <div className='card-body flex-row items-center text-center'>
                  {stampsIsLoading ? (
                    <img className='items-center' src='/images/loadingSpinner.svg' alt='Loading...' />
                  ) : (
                    <h2 className='card-title text-sm md:text-lg flex flex-col'>
                      <span>
                        <AiFillGift size='32' />
                      </span>
                      Rewards Handed out
                      <span>{Math.floor(campaignStampsCount.redeemedCount / campaign.goal)}</span>
                    </h2>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Stamps Table*/}
          <div className='overflow-x-auto w-full pb-20'>
            <h2 className='text-2xl font-bold mb-4'>Stamp Transaction History</h2>
            <table className='table mb-16 w-full min-w-full shadow-lg table-zebra'>
              <thead>
                <tr>
                  <th className='text-xxxs sm:text-xs lg:text-md md:text-sm px-1 pl-4'>Date</th>
                  <th className='text-xxxs sm:text-xs lg:text-md md:text-sm px-1'>Customer Name</th>
                  <th className='text-xxxs sm:text-xs lg:text-md md:text-sm px-1'>Order Value</th>
                  <th className='text-xxxs sm:text-xs lg:text-md md:text-sm px-1'>Number of Stamps</th>
                  <th className='text-xxxs sm:text-xs lg:text-md md:text-sm px-1'>Server Details</th>
                  <th className='text-xxxs sm:text-xs lg:text-md md:text-sm px-1'>Stamp Status</th>
                </tr>
              </thead>
              <tbody>
                {stampsIsLoading ? (
                  Array(10)
                    .fill(0)
                    .map((_, index) => <SkeletonRow key={index} />)
                ) : filteredStamps.length > 0 ? (
                  groupStampsByOrderNumber(filteredStamps)
                    .slice(startIndex, endIndex)
                    .map((group) => (
                      <StampItem
                        onRevokeClick={handleRevokeClick}
                        key={group.orderNumber}
                        user={user}
                        stamp={group.stamps[0]}
                        hideDetails={true}
                        numberOfStamps={group.stamps.length}
                        hideStampCount={false}
                        orderValue={true}
                        revokedStamps={group.revokedStamps}
                      />
                    ))
                ) : (
                  <tr>
                    <td colSpan={6}>No stamps found for given date range</td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <th className='text-xxxs sm:text-xs lg:text-md md:text-sm px-1 pl-4 pl-4'>Date</th>
                  <th className='text-xxxs sm:text-xs lg:text-md md:text-sm px-1 pl-4'>Customer Name</th>
                  <th className='text-xxxs sm:text-xs lg:text-md md:text-sm px-1 pl-4'>Order Value</th>
                  <th className='text-xxxs sm:text-xs lg:text-md md:text-sm px-1 pl-4'>Number of Stamps</th>
                  <th className='text-xxxs sm:text-xs lg:text-md md:text-sm px-1 pl-4'>Server Details</th>
                  <th className='text-xxxs sm:text-xs lg:text-md md:text-sm px-1 pl-4'>Stamp Status</th>
                </tr>
              </tfoot>
            </table>
          </div>
          {totalPages > 1 && <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />}
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default Campaign
