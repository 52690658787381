import React, { useState, useEffect } from 'react'
import { CountryDropdown } from 'react-country-region-selector'
import { useParams, useNavigate } from 'react-router-dom'
import { customToast } from './CustomToast'
import { businessCategoryToTypesMapping } from '../utils/businessCategoryToTypes'
import PostCodeChecker from './PostCodeChecker'
import { processPayment, getProducts, resetPaymentStatus } from '../features/payments/paymentsSlice'
import { getCurrencyForCountry } from './utility'
import { isPasswordSecure, getPasswordStrength } from '../utils/passwordUtils'
import SquarePaymentButton from './SquarePaymentButton'
import AutoFillButton from './AutoFillButton'
import { useSelector, useDispatch } from 'react-redux'
import ModalPopup from './ModalPopup'

const PaymentForm = ({ onSubmit, formData, setFormData, children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { productId } = useParams() // Get productId from URL
  const { products, isLoading, error, subscriptionIsProcessing } = useSelector((state) => state.payments)

  const selectedProduct = products ? products.find((product) => product.id === productId) : null
  const subscriptionDetails = useSelector((state) => state.payments.subscriptionDetails)

  const [isValidPostCode, setIsValidPostCode] = useState(true)
  const [showOtherBusinessInput, setShowOtherBusinessInput] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [passwordStrength, setPasswordStrength] = useState(0)
  const [passwordMatchError, setPasswordMatchError] = useState('')
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [showPaymentForm, setShowPaymentForm] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  console.log('Rendering PaymentForm')

  const {
    businessName,
    name,
    email,
    password,
    password2,
    accessPass,
    postcode,
    businessType,
    businessCategory,
    otherBusinessType,
    country,
    agentReferralCode,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
  } = formData

  function formatPostcode(p) {
    // Remove all spaces and convert to uppercase
    p = p.replace(/\s/g, '').toUpperCase()

    // UK postcode regex
    var postcodeRegEx = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/

    // If it's a complete postcode, format it
    if (postcodeRegEx.test(p)) {
      return p.slice(0, -3) + ' ' + p.slice(-3)
    }

    // If it's not a complete postcode yet, just return the uppercase version
    return p
  }

  const onChange = (e) => {
    let { name, value } = e.target

    if (name === 'businessType') {
      setShowOtherBusinessInput(value === 'Other')
    }

    if (name === 'postcode') {
      value = formatPostcode(value)
    }

    if (name === 'password') {
      if (!isPasswordSecure(value)) {
        setPasswordError('Password must be at least 8 characters long, contain uppercase and lowercase letters and numbers.')
      } else {
        setPasswordError('')
      }
      setPasswordStrength(getPasswordStrength(value))
    }

    if (name === 'password2') {
      if (value !== formData.password) {
        setPasswordMatchError('Passwords do not match')
      } else {
        setPasswordMatchError('')
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const validatePostCode = (postcode) => {
    const regex = new RegExp(/^(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKS-UW]) ?[0-9][ABD-HJLNP-UW-Z]{2})$/i)
    return regex.test(postcode)
  }

  const isValidForm = () => {
    console.log('Validating form...') // Debug log
    if (formData.password !== formData.password2) {
      customToast('Passwords do not match')
      return false
    }
    if (!isPasswordSecure(formData.password)) {
      customToast('Password must be at least 8 characters long, contain uppercase and lowercase letters and numbers.')
      return false
    }
    // Add any other form validation checks here
    console.log('Form is valid') // Debug log
    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('Form submitted')
    setIsSubmitting(true)

    if (isValidForm()) {
      try {
        console.log('Submitting form data...')
        // Add currency based on country before submitting
        formData.currency = getCurrencyForCountry(formData.country)
        await onSubmit(formData)
        console.log('Form data submitted successfully')
        setShowPaymentForm(false)
      } catch (error) {
        console.error('Form submission error:', error)
        customToast('An error occurred while submitting the form. Please try again.')
      } finally {
        setIsSubmitting(false)
      }
    } else {
      console.log('Form is not valid')
      setIsSubmitting(false)
    }
  }

  const handlePaymentSuccess = (paymentData) => {
    console.log('🚀 ~ handlePaymentSuccess ~ paymentData:', paymentData)

    if (isValidPostCode) {
      const currency = getCurrencyForCountry(formData.country)

      try {
        dispatch(
          processPayment({
            currency,
            paymentToken: paymentData,
            email: formData.email,
          })
        )
      } catch (error) {
        console.error('Payment submission error:', error)
        customToast('An error occurred while processing the payment. Please try again.')
      }
    } else {
      customToast.error('Invalid UK Postcode')
    }
  }

  const handleCountryChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      country: val,
      currency: getCurrencyForCountry(val),
    }))
  }

  const handleAutoFill = (testData) => {
    setFormData(testData)
  }

  const getProductAmount = () => {
    if (selectedProduct && selectedProduct.subscriptionPlanData) {
      const variation = selectedProduct.subscriptionPlanData.subscriptionPlanVariations[0]
      if (variation) {
        const recurringPhase = variation.subscriptionPlanVariationData.phases.find((phase) => phase.ordinal === '1')
        if (recurringPhase) {
          return recurringPhase.pricing.priceMoney.amount
        }
      }
    }
    return 0
  }

  const getProductCurrency = () => {
    if (selectedProduct && selectedProduct.subscriptionPlanData) {
      const variation = selectedProduct.subscriptionPlanData.subscriptionPlanVariations[0]
      if (variation) {
        const recurringPhase = variation.subscriptionPlanVariationData.phases.find((phase) => phase.ordinal === '1')
        if (recurringPhase) {
          return recurringPhase.pricing.priceMoney.currency
        }
      }
    }
    return 'GBP'
  }

  useEffect(() => {
    if (subscriptionDetails) {
      navigate('/payment-success')
      dispatch(resetPaymentStatus())
    }
  }, [subscriptionDetails, navigate, dispatch])

  return (
    <>
      {showPaymentForm ? (
        <>
          {children}
          <AutoFillButton onAutoFill={handleAutoFill} />
          <form onSubmit={handleSubmit} className='space-y-4'>
            <div className='form-group relative mb-2'>
              <input
                type='text'
                className={`global-input peer ${isSubmitting ? 'bg-gray-50 cursor-not-allowed opacity-60' : 'bg-white'}`}
                placeholder=' '
                id='businessName'
                name='businessName'
                value={businessName}
                onChange={onChange}
                required
                disabled={isSubmitting}
              />
              <label
                htmlFor='businessName'
                className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
                Business Name
              </label>
            </div>

            <div className='form-group relative mb-2'>
              <input
                type='text'
                className={`global-input peer ${isSubmitting ? 'bg-gray-50 cursor-not-allowed opacity-60' : 'bg-white'}`}
                placeholder=' '
                id='name'
                name='name'
                value={name}
                onChange={onChange}
                required
                disabled={isSubmitting}
              />
              <label
                htmlFor='name'
                className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
                Your name
              </label>
            </div>

            <div className='form-group relative mb-2'>
              <input
                type='email'
                className={`global-input peer ${isSubmitting ? 'bg-gray-50 cursor-not-allowed opacity-60' : 'bg-white'}`}
                placeholder=' '
                id='email'
                name='email'
                value={email}
                onChange={onChange}
                required
                disabled={isSubmitting}
              />
              <label
                htmlFor='email'
                className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
                Email Address
              </label>
            </div>

            {/* COUNTRY*/}
            <div className='relative mb-2'>
              <CountryDropdown
                value={country}
                onChange={handleCountryChange}
                className={`global-input-select ${isSubmitting ? 'bg-gray-50 cursor-not-allowed opacity-60' : 'bg-white'}`}
                defaultOptionLabel='Select country'
                priorityOptions={['GB', 'US', 'CA']}
                showDefaultOption={true}
                valueType='short'
                placeholder=''
                disabled={isSubmitting || true}
              />
              <label
                htmlFor='businessCategory'
                className={`absolute left-3 -top-0.5 text-gray-500 pointer-events-none text-sm  px-1 transition-all
                  ${country ? '-top-2.5' : 'top-1'}`}>
                Your Country
              </label>
            </div>

            {country === 'GB' && (
              <>
                <div className='flex items-center gap-2 mb-2'>
                  <p>To check the eligibility of your postcode,</p>
                  <label htmlFor='postcode-modal' className='link cursor-pointer'>
                    click here
                  </label>
                </div>

                <ModalPopup
                  id='postcode-modal'
                  title='Check Postcode Eligibility'
                  onClose={() => {
                    // Add any cleanup or state reset logic here
                  }}>
                  <PostCodeChecker />
                </ModalPopup>

                <div className='form-group relative mb-2'>
                  <input
                    type='text'
                    className={`global-input peer ${isSubmitting ? 'bg-gray-50 cursor-not-allowed opacity-60' : 'bg-white'}`}
                    placeholder=' '
                    id='postcode'
                    name='postcode'
                    value={postcode}
                    onChange={onChange}
                    required
                    disabled={isSubmitting}
                  />
                  <label
                    htmlFor='postcode'
                    className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
                    Postcode
                  </label>
                </div>
                <div className='relative mb-2'>
                  <select
                    className={`global-input-select ${isSubmitting ? 'bg-gray-50 cursor-not-allowed opacity-60' : 'bg-white'}`}
                    id='payment-businessCategory'
                    name='businessCategory'
                    value={businessCategory}
                    onChange={onChange}
                    required
                    disabled={isSubmitting}>
                    <option value=''>Please select an industry sector</option>
                    <option value='Entertainment'>Entertainment</option>
                    <option value='Hair/Beauty'>Hair/Beauty</option>
                    <option value='Health/Fitness'>Health/Fitness</option>
                    <option value='High Street Shop'>High Street Shop</option>
                    <option value='High Street Shop/Clothing'>High Street Shop/Clothing</option>
                    <option value='High Street Shop/DIY-Building'>High Street Shop/DIY-Building</option>
                    <option value='High Street Shop/Food & Drink'>High Street Shop/Food & Drink</option>
                    <option value='High Street Shop/Gardening'>High Street Shop/Gardening</option>
                    <option value='High Street Shop/Health & Wellness'>High Street Shop/Heath & Wellness</option>
                    <option value='High Street Shop/Motoring'>High Street Shop/Motoring</option>
                    <option value='Places to Stay'>Places to Stay</option>
                    <option value='Pubs/Bars'>Pubs/Bars</option>
                    <option value='Restaurants/Cafes'>Restaurants/Cafes</option>
                    <option value='Takeaways'>Takeaways</option>
                  </select>
                  <label
                    htmlFor='businessCategory'
                    className={`absolute left-3 text-gray-400 pointer-events-none text-sm  px-1 transition-all
                      ${businessCategory ? '-top-1.2' : 'top-1'}`}>
                    Your Industry Sector
                  </label>
                </div>

                <div className='relative mb-2'>
                  <select
                    className={`global-input-select ${isSubmitting ? 'bg-gray-50 cursor-not-allowed opacity-60' : 'bg-white'}`}
                    id='payment-businessType'
                    name='businessType'
                    value={businessType}
                    onChange={onChange}
                    required
                    disabled={isSubmitting}>
                    <option value=''>{businessCategory ? 'Please select a Business type' : 'Please select an industry sector first'}</option>
                    {businessCategory &&
                      businessCategoryToTypesMapping[businessCategory].map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                  </select>
                  <label
                    htmlFor='businessCategory'
                    className={`absolute left-3 text-gray-400 pointer-events-none text-sm  px-1 transition-all
                      ${businessType ? '-top-1.3' : 'top-1'}`}>
                    Your Business Type
                  </label>
                </div>
                {showOtherBusinessInput && (
                  <div className='form-group relative mb-2'>
                    <input
                      type='text'
                      className={`global-input peer ${isSubmitting ? 'bg-gray-50 cursor-not-allowed opacity-60' : 'bg-white'}`}
                      placeholder=' '
                      id='otherBusinessType'
                      name='otherBusinessType'
                      value={otherBusinessType}
                      onChange={onChange}
                      required
                      disabled={isSubmitting}
                    />
                    <label
                      htmlFor='otherBusinessType'
                      className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
                      Specify Business Type
                    </label>
                  </div>
                )}
              </>
            )}

            <div className='form-group relative mb-2'>
              <input
                type='password'
                className={`global-input peer ${isSubmitting ? 'bg-gray-50 cursor-not-allowed opacity-60' : 'bg-white'}`}
                placeholder=' '
                id='password'
                name='password'
                value={password}
                onChange={onChange}
                required
                disabled={isSubmitting}
              />
              <label
                htmlFor='password'
                className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
                Password
              </label>
            </div>

            {passwordError && <p className='text-red-500 text-xs mt-1'>{passwordError}</p>}
            <div className='mt-1 h-2 w-full bg-gray-200 rounded-full'>
              <div
                className={`h-full rounded-full ${
                  passwordStrength === 0
                    ? 'bg-red-500'
                    : passwordStrength === 1
                    ? 'bg-orange-500'
                    : passwordStrength === 2
                    ? 'bg-yellow-500'
                    : passwordStrength === 3
                    ? 'bg-lime-500'
                    : passwordStrength === 4
                    ? 'bg-green-500'
                    : 'bg-green-600'
                }`}
                style={{ width: `${passwordStrength * 20}%` }}></div>
            </div>
            <p className='text-xs mt-1 mb-2'>
              {passwordStrength === 0
                ? 'Very weak'
                : passwordStrength === 1
                ? 'Weak'
                : passwordStrength === 2
                ? 'Fair'
                : passwordStrength === 3
                ? 'Good'
                : passwordStrength === 4
                ? 'Strong'
                : 'Very strong'}
            </p>

            <div className='form-group relative mb-2'>
              <input
                type='password'
                className={`global-input peer ${isSubmitting ? 'bg-gray-50 cursor-not-allowed opacity-60' : 'bg-white'}`}
                placeholder=' '
                id='password2'
                name='password2'
                value={password2}
                onChange={onChange}
                required
                disabled={isSubmitting}
              />
              <label
                htmlFor='password2'
                className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
                Confirm Password
              </label>
            </div>

            <div className='form-group relative mb-2'>
              <input
                type='password'
                className={`global-input peer ${isSubmitting ? 'bg-gray-50 cursor-not-allowed opacity-60' : 'bg-white'}`}
                placeholder=' '
                id='accessPass'
                name='accessPass'
                value={accessPass}
                onChange={onChange}
                disabled={isSubmitting}
              />
              <label
                htmlFor='accessPass'
                className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
                Access Password
              </label>
            </div>

            <div className='form-group relative mb-2'>
              <input
                type='text'
                className={`global-input peer ${isSubmitting ? 'bg-gray-50 cursor-not-allowed opacity-60' : 'bg-white'}`}
                placeholder=' '
                id='agentReferralCode'
                name='agentReferralCode'
                value={agentReferralCode}
                onChange={onChange}
                disabled={isSubmitting}
              />
              <label
                htmlFor='agentReferralCode'
                className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
                Agent Code (optional)
              </label>
            </div>

            {/* Middle Content */}
            <div className='flex flex-col space-y-6 md:flex-row justify-between items-center py-6 md:space-y-0 md:space-x-6'>
              <button
                type='submit'
                disabled={isSubmitting}
                className='btn-primary flex p-6 rounded-md text-white w-full justify-center items-center space-x-4 font-sans font-bold shadow-lg px-9 hover:bg-opacity-90 hover:shadow-lg border transition hover:-translate-y-0.5 duration-150 mb-20 disabled:opacity-70 disabled:cursor-not-allowed'>
                {isSubmitting ? (
                  <>
                    <svg className='animate-spin -ml-1 mr-3 h-5 w-5 text-white' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
                      <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
                      <path
                        className='opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                    </svg>
                    <span>Processing...</span>
                  </>
                ) : (
                  <>
                    <span>Submit and Proceed to Payment</span>
                    <img src='images/next-arrow.svg' alt='' />
                  </>
                )}
              </button>
            </div>
          </form>
        </>
      ) : (
        // Payment content - without the headings
        <div className='flex flex-col items-center space-y-6 p-4'>
          <h3 className='text-4xl font-bold'>Complete Payment</h3>
          <p className='text-gray-600'>Please complete your payment to activate your subscription</p>
          <SquarePaymentButton
            productId={productId}
            onPaymentSuccess={handlePaymentSuccess}
            amount={getProductAmount()}
            currency={getProductCurrency()}
            setShowPaymentForm={setShowPaymentForm}
          />
        </div>
      )}

      {error && <div className='text-red-500 text-center p-4'>{error}</div>}
    </>
  )
}

export default PaymentForm
