import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import { useRef, createRef } from 'react'
import Tippy from '@tippyjs/react'
import { customToast } from '../components/CustomToast'
import { useEffect, useState } from 'react'

import {
  getSoldTEDSTransactions,
  fetchTEDSWallet,
  exchangeTEDSForCash,
  reset,
  linkBankAccount,
  getBankAccountStatus,
} from '../features/teds/tedsSlice'

import { AiOutlineInfoCircle } from 'react-icons/ai'
import Pagination from '../components/Pagination'
import 'tippy.js/dist/tippy.css'
import SkeletonRow from '../components/SkeletonRow'
import { verifySecurityCode, checkSecurityCodeStatus, reset as authReset } from '../features/auth/authSlice'

const SellTEDS = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const { bankAccount } = useSelector((state) => state.teds)
  const [isBankModalOpen, setIsBankModalOpen] = useState(false)
  const [formData, setFormData] = useState({
    accountHolderName: '',
    accountNumber: '',
    sortCode: '',
    bankName: '',
  })
  const [securityDigits, setSecurityDigits] = useState(['', '', '', '', '', ''])
  const digitRefs = useRef([...Array(6)].map(() => createRef()))
  const [isCodeVerified, setIsCodeVerified] = useState(false)
  const [verificationError, setVerificationError] = useState(null)

  const amountInputRef = useRef(null)

  const {
    soldTEDSTransactions: { transactions, totalPages },
    // isError,
    isSuccess: isSuccessTEDS,
    // isLoading,
    message: messageTEDS,
    isLoading: isLoadingTEDS,
  } = useSelector((state) => state.teds)

  const { accountLinkUrl, stripeAccount, isError, isSuccess, isLoading, message } = useSelector((state) => state.subscription)

  const { user } = useSelector((state) => state.auth)

  useEffect(() => {
    if (isError) {
      customToast.error(message)
      dispatch(reset())
    }
    if (isSuccessTEDS) {
      customToast.success(messageTEDS)
      dispatch(fetchTEDSWallet())
      dispatch(reset())
    }
  }, [isSuccess, isSuccessTEDS, messageTEDS, dispatch, isError, message, accountLinkUrl])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
    }
    dispatch(getSoldTEDSTransactions(filterData))

    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage])

  useEffect(() => {
    dispatch(getBankAccountStatus())
  }, [dispatch])

  // Update form data when bank details are fetched or changed
  useEffect(() => {
    if (bankAccount.details) {
      setFormData({
        accountHolderName: bankAccount.details.accountHolderName || '',
        accountNumber: bankAccount.details.accountNumber || '',
        sortCode: bankAccount.details.sortCode || '',
        bankName: bankAccount.details.bankName || '',
      })
    }
  }, [bankAccount.details])

  useEffect(() => {
    dispatch(checkSecurityCodeStatus())
  }, [dispatch])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleExchangeTEDSClick = async () => {
    if (!user.hasSecurityCode) {
      customToast.error('Please set up your security code in your Profile before exchanging TEDS')
      return
    }
    document.getElementById('exchange-modal').checked = true
    // Add slight delay to ensure modal is rendered before focusing
    setTimeout(() => {
      digitRefs.current[0].current.focus()
    }, 100)
  }

  const handleVerifyCode = async () => {
    const combinedCode = securityDigits.join('')
    console.log('🔑 Attempting to verify security code')
    try {
      const resultAction = await dispatch(verifySecurityCode(combinedCode))
      console.log('🔄 Verification result:', resultAction)

      if (verifySecurityCode.fulfilled.match(resultAction)) {
        console.log('✅ Security code verified successfully')
        setIsCodeVerified(true)
        setVerificationError(null)
        setSecurityDigits(['', '', '', '', '', '']) // Clear all digits
        customToast.success('Security code verified successfully')
        dispatch(authReset())
      } else if (verifySecurityCode.rejected.match(resultAction)) {
        console.log('❌ Security code verification failed:', resultAction.payload)
        setVerificationError(resultAction.payload)
        setSecurityDigits(['', '', '', '', '', '']) // Clear all digits on failure
        customToast.error(resultAction.payload || 'Failed to verify security code')
        setIsCodeVerified(false)
        // Focus on first input after clearing
        digitRefs.current[0].current.focus()
      }
    } catch (error) {
      console.error('❌ Error during verification:', error)
      setVerificationError(error.message)
      setSecurityDigits(['', '', '', '', '', '']) // Clear all digits on error
      customToast.error(error.message || 'Failed to verify security code')
      setIsCodeVerified(false)
      // Focus on first input after clearing
      digitRefs.current[0].current.focus()
    }
  }

  const handleExchange = async () => {
    if (!isCodeVerified) {
      customToast.error('Please verify your security code first')
      return
    }

    const amount = amountInputRef.current.value

    if (!amount || amount <= 0) {
      customToast.error('Please enter a valid amount')
      return
    }

    try {
      dispatch(exchangeTEDSForCash(amount))
      document.getElementById('exchange-modal').checked = false
      amountInputRef.current.value = ''
      // Reset verification state for next exchange
      setIsCodeVerified(false)
      setSecurityDigits(['', '', '', '', '', '']) // Clear all digits
    } catch (error) {
      console.error('Error exchanging TEDS for cash:', error)
      customToast.error('Failed to submit TEDS exchange request')
    }
  }

  const handleBankAccountSubmit = async (e) => {
    e.preventDefault()
    try {
      await dispatch(linkBankAccount(formData)).unwrap()
      setIsBankModalOpen(false)
      customToast.success('Bank account details updated successfully')
    } catch (error) {
      customToast.error(error.toString())
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleDigitChange = (index, value) => {
    // Only allow numbers
    if (!/^\d*$/.test(value)) return

    const newDigits = [...securityDigits]
    newDigits[index] = value

    setSecurityDigits(newDigits)

    // Auto-focus next input with null check
    if (value && index < 5 && digitRefs.current[index + 1]?.current) {
      digitRefs.current[index + 1].current.focus()
    }
  }

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !securityDigits[index] && index > 0) {
      if (digitRefs.current[index - 1]?.current) {
        digitRefs.current[index - 1].current.focus()
      }
    } else if (e.key === 'Enter' && !securityDigits.includes('')) {
      handleVerifyCode()
    }
  }

  return (
    <div className='pb-20'>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      <div className='flex mb-8 justify-between mt-2'>
        <div className='flex space-x-2'>
          {bankAccount.isLinked ? (
            <button className='btn-primary btn' onClick={handleExchangeTEDSClick}>
              Exchange TEDS For Cash
            </button>
          ) : (
            <button className='btn-primary btn' onClick={() => setIsBankModalOpen(true)}>
              Link Bank Account
            </button>
          )}
          {bankAccount.isLinked && (
            <Tippy content='Update your bank account details' placement='top' arrow={true} delay={[200, 0]}>
              <button className='btn-primary btn' onClick={() => setIsBankModalOpen(true)}>
                Update Bank Details
              </button>
            </Tippy>
          )}
        </div>
      </div>
      <div className='overflow-x-auto pb-8'>
        <table className='table mb-16 w-full min-w-full table-zebra'>
          <thead>
            <tr>
              <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Date & Time</th>
              <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>TEDS Quantity</th>
              <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Cash Amount</th>
              <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Status</th>
            </tr>
          </thead>
          <tbody>
            {isLoadingTEDS || isLoading ? (
              Array(10)
                .fill(0)
                .map((_, index) => <SkeletonRow columns={4} key={index} />)
            ) : !Array.isArray(transactions) || transactions.length === 0 ? (
              <tr>
                <td colSpan='4'>No Transactions found.</td>
              </tr>
            ) : (
              transactions.map((transaction, index) => (
                <tr className={index % 2 === 0 ? 'bg-base-200' : ''} key={transaction.id}>
                  <td className='table-optimized'>
                    {new Date(transaction.createdAt).toLocaleDateString()}
                    <br />
                    <span className='badge badge-sm text-xxxs md:text-xs'>
                      {new Date(transaction.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                    </span>
                  </td>
                  <td className='table-optimized'>
                    <p className='text-red-500'>-{transaction.tedsAmount}</p>
                  </td>
                  <td className='table-optimized'>
                    <p className='text-green-500'>
                      +{user.currency}
                      {transaction.currencyValue}
                    </p>
                  </td>
                  <td className='table-optimized'>
                    {transaction.status}
                    {transaction.status === 'Pending' && (
                      <div
                        className='campaign-tooltip'
                        data-tip='To comply with anti-money laundering regulations and financial institution requirements, all TEDS sale requests undergo a thorough 2-7 business day review process. We appreciate your patience as we work to ensure the security and integrity of our platform.'>
                        <AiOutlineInfoCircle />
                      </div>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <input type='checkbox' id='exchange-modal' className='modal-toggle' />
      <div className='modal modal-bottom sm:modal-middle custom-zindex-less'>
        <div className='modal-box'>
          <h3 className='font-bold text-lg'>Exchange TEDS For Cash</h3>
          <p className='text-gray-400 text-sm'>Current TEDS Balance - {user.wallet.tedsBalance + user.wallet.madTedsBalance}</p>

          {!isCodeVerified ? (
            // Security code verification section
            <div className='py-4'>
              <label className='label flex justify-center'>
                <span className='label-text'>Please enter your 6-digit security code:</span>
              </label>
              <div className='flex gap-2 justify-center my-4'>
                {securityDigits.map((digit, index) => (
                  <input
                    key={index}
                    type='password'
                    ref={digitRefs.current[index]}
                    value={digit}
                    onChange={(e) => handleDigitChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    maxLength={1}
                    className='w-12 h-12 text-center input input-bordered'
                    inputMode='numeric'
                    pattern='\d*'
                    autoComplete='off'
                  />
                ))}
              </div>
              {verificationError && <p className='text-error text-sm mt-2'>{verificationError}</p>}
              <div className='modal-action'>
                <button className='btn btn-primary' onClick={handleVerifyCode} disabled={securityDigits.some((digit) => digit === '')}>
                  Verify Code
                </button>
                <label htmlFor='exchange-modal' className='btn'>
                  Cancel
                </label>
              </div>
            </div>
          ) : (
            // TEDS exchange section
            <div className='py-4'>
              <label className='label'>
                <span className='label-text'>Amount of TEDS to Exchange:</span>
              </label>
              <input type='number' placeholder='Enter amount' className='input input-bordered w-full' ref={amountInputRef} />
              <p className='text-sm text-gray-500 mb-4'>
                Please note that to ensure compliance with anti-money laundering regulations, all TEDS exchange requests are subject to a mandatory
                2-7 business day review period. We appreciate your understanding as we prioritize the security and integrity of our platform.
              </p>
              <div className='modal-action'>
                <button className='btn btn-primary' onClick={handleExchange}>
                  Exchange
                </button>
                <label htmlFor='exchange-modal' className='btn'>
                  Cancel
                </label>
              </div>
            </div>
          )}
        </div>
      </div>
      <input type='checkbox' id='bank-account-modal' className='modal-toggle' checked={isBankModalOpen} />
      <div className='modal modal-bottom sm:modal-middle custom-zindex'>
        <div className='modal-box'>
          <h3 className='font-bold text-lg'>{bankAccount.isLinked ? 'Update Bank Account Details' : 'Link Your Bank Account'}</h3>
          <form onSubmit={handleBankAccountSubmit} className='space-y-4'>
            <div>
              <label className='label'>Account Holder Name</label>
              <input
                type='text'
                name='accountHolderName'
                className='input input-bordered w-full'
                value={formData.accountHolderName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <label className='label'>Account Number (8 digits)</label>
              <input
                type='text'
                name='accountNumber'
                className='input input-bordered w-full'
                value={formData.accountNumber}
                onChange={handleInputChange}
                pattern='[0-9]{8}'
                required
              />
            </div>
            <div>
              <label className='label'>Sort Code (6 digits)</label>
              <input
                type='text'
                name='sortCode'
                className='input input-bordered w-full'
                value={formData.sortCode}
                onChange={handleInputChange}
                pattern='[0-9-]{6,8}'
                required
              />
            </div>
            <div>
              <label className='label'>Bank Name</label>
              <input
                type='text'
                name='bankName'
                className='input input-bordered w-full'
                value={formData.bankName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className='modal-action'>
              <button type='submit' className='btn btn-primary'>
                {bankAccount.isLinked ? 'Update Details' : 'Link Account'}
              </button>
              <button type='button' className='btn' onClick={() => setIsBankModalOpen(false)}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
      {totalPages > 1 && <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />}
    </div>
  )
}

export default SellTEDS
