import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import { customToast } from '../components/CustomToast'
import { useEffect, useState } from 'react'
import { getPartnerFeedback, reset } from '../features/customer/customersSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
import { format } from 'date-fns'
import { Star, StarHalf } from 'lucide-react'
import { Tooltip } from 'react-tooltip'
import SkeletonRow from '../components/SkeletonRow'

const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating)
  const hasHalfStar = rating % 1 >= 0.5

  return (
    <div className='flex items-center'>
      {[...Array(5)].map((_, i) => {
        if (i < fullStars) {
          return <Star key={i} className='w-4 h-4 text-yellow-400 fill-current' />
        } else if (i === fullStars && hasHalfStar) {
          return <StarHalf key={i} className='w-4 h-4 text-yellow-400 fill-current' />
        } else {
          return <Star key={i} className='w-4 h-4 text-gray-300' />
        }
      })}
    </div>
  )
}

const CustomerFeedback = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const {
    feedbacks: { feedback, totalPages } = { feedback: [], totalPages: 0 },
    isError,
    isSuccess,
    isLoading,
    message,
  } = useSelector((state) => state.customers) || {}

  console.log(feedback, 'feedbacks')

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      customToast.error(message)
      dispatch(reset())
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 15,
      searchTerm: debouncedSearchTerm,
    }
    dispatch(getPartnerFeedback(filterData))

    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  const formatDate = (isoString) => {
    return format(new Date(isoString), "dd MMM yyyy 'at' HH:mm")
  }

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text
    return text.substr(0, maxLength) + '...'
  }

  return (
    <div className='pb-20'>
      <div className='flex mb-4'>
        <BackButton />
      </div>
      <div className='flex mb-8 justify-between mt-2'>
        <input
          type='text'
          placeholder='Search by customer name, or feedback content'
          value={searchTerm}
          onChange={handleSearchChange}
          className='input w-full max-w-md mr-2 text-xs'
        />
      </div>
      <div className='overflow-x-auto pb-8'>
        <table className='table mb-16 w-full min-w-full table-zebra'>
          <thead>
            <tr>
              <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Date</th>
              <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Customer Name</th>
              <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Rating</th>
              <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Feedback</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              Array(10)
                .fill(0)
                .map((_, index) => <SkeletonRow key={index} />)
            ) : !Array.isArray(feedback) || feedback.length === 0 ? (
              <tr>
                <td colSpan='5'>No customer feedback found.</td>
              </tr>
            ) : (
              feedback.map((feedbackItem, index) => {
                const truncatedFeedback = truncateText(feedbackItem.feedback, 50)
                const isTruncated = truncatedFeedback !== feedbackItem.feedback

                return (
                  <tr className={index % 2 === 0 ? 'bg-base-200' : ''} key={feedbackItem._id}>
                    <td className='table-optimized'>{formatDate(feedbackItem.createdAt)}</td>
                    <td className='table-optimized'>{feedbackItem.user.userName}</td>
                    <td className='table-optimized'>
                      <StarRating rating={feedbackItem.rating} />
                    </td>
                    <td className='table-optimized'>
                      {isTruncated ? (
                        <>
                          <span data-tooltip-id={`feedback-${feedbackItem._id}`} data-tooltip-content={feedbackItem.feedback}>
                            {truncatedFeedback}
                          </span>
                          <Tooltip id={`feedback-${feedbackItem._id}`} className='custom-tooltip' place='top' multiline={true} />
                        </>
                      ) : (
                        <span>{feedbackItem.feedback}</span>
                      )}
                    </td>
                  </tr>
                )
              })
            )}
          </tbody>
        </table>
      </div>
      {totalPages > 1 && <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />}
    </div>
  )
}

export default CustomerFeedback
