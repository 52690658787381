import Profile from '../components/Profile'
import ProfileServer from '../components/ProfileServer'
import { reset } from '../features/auth/authSlice'
import PostcodeSectors from '../components/PostcodeSectors'
import Subscription from '../components/Subscription'
import MapSection from '../components/MapSection'
import SocialLinks from '../components/SocialLinks'
import EditProfilePage from '../components/EditProfilePage'
import GoogleReviews from '../components/GoogleReviews'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import PasswordAndSecurity from '../components/PasswordAndSecurity'
import { useNavigate } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { customToast } from '../components/CustomToast'
import config from '../config'

const Settings = () => {
  const { user, isError, isSuccess, message } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const profileRef = useRef()
  const socialRef = useRef()
  const locationRef = useRef()
  const subscriptionRef = useRef()
  const passwordRef = useRef()
  const postcodeSectorsRef = useRef()
  const profilePageRef = useRef()
  const googleReviewsRef = useRef()
  const { hideElements } = config
  const SCROLL_OFFSET = 70

  const handleClick = (ref) => {
    const yOffset = -SCROLL_OFFSET
    const element = ref.current
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  useEffect(() => {
    if (isError && message !== 'Location data not found for this user') {
      customToast.error(message)
    }

    if (isSuccess) {
      customToast.success(message?.message || message)
    }
    dispatch(reset())
  }, [isError, isSuccess, dispatch, message])

  if (!user) {
    navigate('/login')
    return null
  }

  return (
    <>
      <Helmet>
        <title>Settings | Loyalty Club PLC</title>
      </Helmet>
      <div className='flex flex-col lg:flex-row bg-[#f1f2f5] min-h-screen pb-20'>
        {/* Settings Menu */}
        {user.role === 'ClientUser' && (
          <div className='md:mr-8 mb-7 z-1'>
            <ul className='sticky top-0 menu lg:w-56 w-full bg-[#f1f2f5]'>
              <li
                className='font-bold rounded-lg hover:cursor-pointer p-3 hover:bg-white text-center lg:text-left'
                onClick={() => handleClick(profileRef)}>
                Profile
              </li>
              <li
                className='font-bold rounded-lg hover:cursor-pointer p-3 hover:bg-white text-center lg:text-left'
                onClick={() => handleClick(postcodeSectorsRef)}>
                Postcode Sectors
              </li>
              <li
                className='font-bold rounded-lg hover:cursor-pointer p-3 hover:bg-white text-center lg:text-left'
                onClick={() => handleClick(socialRef)}>
                Social links
              </li>
              <li
                className='font-bold rounded-lg hover:cursor-pointer p-3 hover:bg-white text-center lg:text-left'
                onClick={() => handleClick(locationRef)}>
                Location Settings
              </li>
              <li
                className='font-bold rounded-lg hover:cursor-pointer p-3 hover:bg-white text-center lg:text-left'
                onClick={() => handleClick(googleReviewsRef)}>
                Google Reviews
              </li>
              <li
                className='font-bold rounded-lg hover:cursor-pointer p-3 hover:bg-white text-center lg:text-left'
                onClick={() => handleClick(profilePageRef)}>
                Edit Profile Page
              </li>
              <li
                className='font-bold rounded-lg hover:cursor-pointer p-3 hover:bg-white text-center lg:text-left'
                onClick={() => handleClick(passwordRef)}>
                Password & Security
              </li>
              {!hideElements && (
                <li
                  className='font-bold rounded-lg hover:cursor-pointer p-3 hover:bg-white text-center lg:text-left'
                  onClick={() => handleClick(subscriptionRef)}>
                  Manage Subscription
                </li>
              )}
            </ul>
          </div>
        )}
        {/* Right hand side content */}
        <div className='w-full max-w-full overflow-x-hidden'>
          {user.role === 'ClientUser' && (
            <>
              <div id='profile' ref={profileRef} className='max-w-full'>
                <Profile />
              </div>
              <div id='postcode-sectors' ref={postcodeSectorsRef}>
                <PostcodeSectors />
              </div>
              <div id='social' ref={socialRef}>
                <SocialLinks />
              </div>
              <div id='location' ref={locationRef}>
                <MapSection />
              </div>
              <div id='google-reviews' ref={googleReviewsRef}>
                <GoogleReviews />
              </div>
              <div id='profile-page' ref={profilePageRef}>
                <EditProfilePage />
              </div>
              <div id='update-password' ref={passwordRef}>
                <PasswordAndSecurity />
              </div>
              {!hideElements && (
                <div id='subscription' ref={subscriptionRef}>
                  <Subscription />
                </div>
              )}
            </>
          )}
          {user.role === 'ServerUser' && (
            <>
              <div id='server-profile' ref={profileRef}>
                <ProfileServer />
              </div>
              <div id='update-password' ref={passwordRef}>
                <PasswordAndSecurity />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Settings
