import React, { useState, useRef } from 'react'
import BarcodeScannerComponent from 'react-qr-barcode-scanner'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { reset as resetCustomers, applyStamp, fetchCustomer, redeemCoupon, clearCustomer } from '../features/customer/customersSlice'
import { getUserDetails, fetchCustomerID, reset } from '../features/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchCampaignStampCount,
  fetchCustomersCampaigns,
  fetchStampCount,
  reset as resetCampaigns,
  updateCampaigns,
  resetCustomerCampaigns,
  fetchActiveCampaigns,
  joinCustomerToCampaign,
  resetCampaignCurrentSpend,
} from '../features/campaign/campaignsSlice'
import { customToast } from '../components/CustomToast'
import { formatDate } from '../utils/dateUtils.js'

const QRreaderDesktop = (props) => {
  const [data, setData] = useState('No result')
  const [customerLoaded, setCustomerLoaded] = useState(false)
  const buySellSound = useRef(new Audio('/sounds/buy-sell.mp3'))
  const [orderValues, setOrderValues] = useState({})
  const [campaignsLoaded, setCampaignsLoaded] = useState(false)
  const [applyStampButtonDisabled, setApplyStampButtonDisabled] = useState(false)
  const [redeemButtonDisabled, setRedeemButtonDisabled] = useState(false)
  const [stampsToBeAwarded, setStampsToBeAwarded] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const isMobile = windowWidth <= 640
  const [campaignsFetched, setCampaignsFetched] = useState(false)

  console.log('Desktop version ran successfully')
  const {
    isSuccess: customersIsSuccess,
    isError: customersIsError,
    isLoading: customersIsLoading,
    stampIsLoading,
    customerStamps = [],
    redeemIsLoading,
    redeemIsSuccess,
    applyStampIsSuccess,
    customer,
    message: customersMessage,
  } = useSelector((state) => state.customers)

  const { user, currentCustomerID } = useSelector((state) => state.auth)

  const {
    customerCampaigns = [],
    countIsLoading,
    isLoading,
    campaignsIsLoading,
    isError: campaignsIsError,
    isSuccess: campaignsIsSuccess,
    message: campaignsMessage,
    campaigns,
    joinedCampaignIsSuccess,
  } = useSelector((state) => state.campaigns)

  // destructure name out of customer
  const name = customer ? customer.name : ''

  const dispatch = useDispatch()

  // reset customers and campaigns
  useEffect(() => {
    if (customersIsSuccess) {
      setCustomerLoaded(true)
      dispatch(resetCustomers())
    }

    if (campaignsIsSuccess) {
      setCampaignsLoaded(true)
      dispatch(resetCampaigns())
    }

    if (joinedCampaignIsSuccess) {
      customToast.success(campaignsMessage)
    }

    if (applyStampIsSuccess) {
      const regularStampsCount = customerStamps.length
      const rewardStampsCount = countRewardStamps(customerStamps)

      buySellSound.current.play()

      if (rewardStampsCount > 0) {
        customToast.success(`Including ${rewardStampsCount} Reward ${rewardStampsCount === 1 ? 'Stamp' : 'Stamps'} for First Purchase!`, {
          duration: 5000,
          icon: '🎉',
        })
      }

      if (regularStampsCount === 1) {
        customToast.success(`1 Stamp Successfully Applied`)
      } else if (regularStampsCount > 1) {
        customToast.success(`${regularStampsCount} Stamps Successfully Applied`)
      }

      dispatch(resetCustomers())
    }

    if (redeemIsSuccess) {
      buySellSound.current.play()
      customToast.success('Coupon Successfully Redeemed')
      dispatch(resetCustomers())
    }

    if (customersIsError) {
      customToast.error(customersMessage)
      dispatch(resetCustomers())
    }

    if (campaignsIsError) {
      customToast.error(campaignsMessage)
      dispatch(resetCampaigns())
    }
  }, [
    dispatch,
    customersIsSuccess,
    campaignsIsSuccess,
    customersIsError,
    campaignsIsError,
    campaignsMessage,
    customersMessage,
    applyStampIsSuccess,
    redeemIsSuccess,
    customerStamps,
    joinedCampaignIsSuccess,
  ])

  function getCustomerID(url) {
    // Extract the part of the URL after the last '/'
    const code = url.split('/').pop()

    // Check if the extracted part is exactly 6 characters (digits or letters a-f)
    if (code && code.length === 6 && /^[0-9a-fA-F]{6}$/.test(code)) {
      return code
    }
    return null
  }

  useEffect(() => {
    // Check if 'data' begins with 'loyaltyclubplc'
    if (data.startsWith('q.loyaltyclubplc')) {
      dispatch(fetchCustomerID(getCustomerID(data)))
    }
  }, [data, dispatch])

  // resets customer current spend if needbe
  useEffect(() => {
    if (customer && customer.client && customerCampaigns) {
      customerCampaigns.forEach((campaign) => {
        if (campaign.campaignType === 'Spend X Amount To Earn a Stamp') {
          const shouldReset = shouldResetAccumulation(customer.client.dateOfLastVisit, campaign.resetAccumulationAfter)
          if (shouldReset) {
            dispatch(resetCampaignCurrentSpend({ customerId: customer.id, campaignId: campaign.id }))
          }
        }
      })
    }
  }, [customerCampaigns, customer, dispatch])

  // set window size to mobile/desktop to fix responsiveness
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    dispatch(getUserDetails())
  }, [dispatch])

  useEffect(() => {
    if (currentCustomerID) {
      dispatch(fetchCustomer(currentCustomerID))
    }
  }, [currentCustomerID, dispatch])

  useEffect(() => {
    if (customerLoaded) {
      dispatch(fetchCustomersCampaigns(currentCustomerID)).then(() => dispatch(fetchStampCount(currentCustomerID)))
      document.getElementById('my-modal-6').checked = true
    }
  }, [customerLoaded, currentCustomerID, dispatch])

  useEffect(() => {
    if (campaignsLoaded) {
      dispatch(updateCampaigns(customer))
    }
  }, [campaignsLoaded, customer, dispatch])

  const handleApplyStamp = (campaignID) => {
    setApplyStampButtonDisabled(true)
    const stampData = {
      campaignID,
      customerID: customer.id,
    }

    if (orderValues[campaignID] && orderValues[campaignID].trim() !== '') {
      stampData.orderValue = orderValues[campaignID]
    }

    dispatch(applyStamp(stampData))
      .then(() => dispatch(fetchCampaignStampCount(stampData)))
      .then(() => dispatch(fetchCustomer(currentCustomerID)))
      .then((updatedCustomer) => {
        dispatch(updateCampaigns(updatedCustomer))
      })
    setOrderValues({})
    setStampsToBeAwarded(0)
    setTimeout(() => {
      setApplyStampButtonDisabled(false)
    }, 1500)
  }

  const handleRedeemCoupon = (campaignID) => {
    setRedeemButtonDisabled(true)
    const couponData = {
      campaignID,
      customerID: customer.id,
    }
    dispatch(redeemCoupon(couponData))
      .then(() => dispatch(fetchCampaignStampCount(couponData)))
      .then(() => dispatch(fetchCustomer(currentCustomerID)))
      .then((updatedCustomer) => {
        dispatch(updateCampaigns(updatedCustomer))
      })
    setTimeout(() => {
      setRedeemButtonDisabled(false)
    }, 1500)
  }

  const resetCustomerData = () => {
    dispatch(reset())
    setCustomerLoaded(false)
    setData('No result')
    dispatch(clearCustomer())
    dispatch(resetCustomerCampaigns())
  }

  const fetchExistingCampaigns = () => {
    dispatch(fetchActiveCampaigns()).then(() => {
      setCampaignsFetched(true)
    })
  }
  //join a customer to a campaign
  const joinToCampaign = (campaign) => {
    const campaignID = campaign._id

    return dispatch(joinCustomerToCampaign({ customerID: currentCustomerID, campaignID }))
      .then(() => dispatch(fetchStampCount(currentCustomerID)))
      .then(() => campaignID)
  }

  // calculates if accumulation is due to be resrt
  const shouldResetAccumulation = (lastVisitDate, resetAfter) => {
    if (!lastVisitDate || !resetAfter || resetAfter === 'never' || resetAfter === null) {
      return false
    }

    const lastVisit = new Date(lastVisitDate)
    const now = new Date()
    const diffTime = now - lastVisit
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

    return diffDays > parseInt(resetAfter, 10)
  }

  //Counts the number of stamps that have the property 'rewardForFirstPurchase' set to true.
  const countRewardStamps = (stamps) => {
    return stamps.filter((stamp) => stamp.rewardForFirstPurchase).length
  }

  return (
    <div className='pb-20'>
      <Helmet>
        <title>Reader | Loyalty Club PLC</title>
      </Helmet>
      <div className='md:w-1/2 md:h-1/2 mx-auto relative'>
        <BarcodeScannerComponent
          width={640}
          height={480}
          onUpdate={(err, result) => {
            if (result) {
              try {
                setData(result.text)
              } catch (error) {
                console.error('Decode error:', error)
              }
            }
          }}
          onError={(error) => {
            console.error('Camera error:', error)
          }}
          facingMode='environment'
          delay={500}
        />

        {/* Corner Guides */}
        <div
          className='absolute border-t-8 border-l-8 border-primary pulse'
          style={{
            width: '50px',
            height: '50px',
            top: 0,
            left: 0,
            position: 'absolute',
          }}
        />
        <div
          className='absolute border-t-8 border-r-8 border-primary pulse'
          style={{
            width: '50px',
            height: '50px',
            top: 0,
            right: 0,
            position: 'absolute',
          }}
        />
        <div
          className='absolute border-b-8 border-l-8 border-primary pulse'
          style={{
            width: '50px',
            height: '50px',
            bottom: 0,
            left: 0,
            position: 'absolute',
          }}
        />
        <div
          className='absolute border-b-8 border-r-8 border-primary pulse'
          style={{
            width: '50px',
            height: '50px',
            bottom: 0,
            right: 0,
            position: 'absolute',
          }}
        />
      </div>
      {customerCampaigns?.length >= 1 ? (
        <>
          <p className='text- mb-3 font-bold'>
            {customerCampaigns.length} active campaigns found for {name}{' '}
          </p>
          <button className='btn' onClick={() => (document.getElementById('my-modal-6').checked = true)}>
            View Campaigns
          </button>
        </>
      ) : (
        <h1 className='text-lg font-bold'> Please Scan a Customer QR Code</h1>
      )}
      {customersIsLoading === true && (
        <div className='spinner-container flex justify-center mt-5'>
          <img className='' src='/images/loadingSpinner.svg' alt='Loading...' /> <p>QR code found, please wait ...</p>
        </div>
      )}

      <input type='checkbox' id='my-modal-6' className='modal-toggle' />
      <div className='modal modal-bottom sm:modal-middle custom-zindex'>
        <div className='modal-box'>
          {campaignsIsLoading === true && (
            <div className='spinner-container flex justify-center mt-5'>
              <img className='' src='/images/loadingSpinner.svg' alt='Loading...' /> <p>Campaigns Loading ...</p>
            </div>
          )}
          {isLoading ? (
            <div className='flex justify-center'>
              {' '}
              <img src='/images/loadingSpinner.svg' alt='Loading...' />
            </div>
          ) : customerCampaigns?.length >= 1 ? (
            <>
              <h1 className='text-[14px] font-bold mb-4'>
                There {customerCampaigns.length === 1 ? 'is' : 'are'} {customerCampaigns.length} active{' '}
                {customerCampaigns.length === 1 ? 'campaign' : 'campaigns'} subscribed to by {customer.name}
              </h1>
              <div className='flex flex-row flex-wrap'>
                <div className='badge mb-2 mr-2'>{customer.client?.friendsSuccessfullyIntroduced?.length || 0} friends successfully introduced</div>
                <div className='badge mb-2'>Accepted Messages - {customer.client?.acceptedGPDR ? 'Yes' : 'No'}</div>
                <div className='badge mb-2 mr-2'>Customer since - {formatDate(customer.client?.dateJoinedClient || new Date())}</div>
                <div className='badge mb-2 mr-2'>Date of last visit - {formatDate(customer.client?.dateOfLastVisit || new Date())}</div>
                <div className='badge mb-2'>Total visits - {customer.client?.numberOfVisits || 0}</div>
              </div>
            </>
          ) : // shows a list of campaigns the customer can join after clicking on 'Get Active Campaigns'
          campaigns.length > 0 && !campaignsFetched ? (
            <div className='grid gid-cols-1-gap4 mb-3'>
              <h1 className='font-bold mb-3'>This customer is eligible to join the following campaigns </h1>
              {campaigns
                .filter((campaign) => new Date(campaign.startDate) <= new Date())
                .map((campaign) => (
                  <div key={campaign._id} className='bg-gray-200 p-3 mb-3 rounded-lg shadow-lg'>
                    <div className='flex justify-between items-center space-x-2 text-sm '>
                      <div className='text-md font-bold text-left'>{campaign.campaignName}</div>
                      <button onClick={() => joinToCampaign(campaign)} className='btn'>
                        Join
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            // SHows a message if the customer is not joined to any campaigns

            campaigns.length === 0 &&
            !campaignsFetched && (
              <>
                <p className='font-bold md:text-2xl text-2xl my-3'> Customer found {customer?.name}</p>
                <p className='font-bold md:text-lg text-base'> No Campaigns have been joined by this customer</p>
                <p className='mb-4 text-center mt-8 text-xs md:text-base'>Would you like to join this customer to one of your existing campaigns?</p>
                <button onClick={fetchExistingCampaigns} className='btn btn-primary'>
                  Get Active Campaigns
                </button>
              </>
            )
          )}

          {customerCampaigns && (
            <>
              {Array.isArray(customerCampaigns) &&
                customerCampaigns.length > 0 &&
                customerCampaigns.map((campaign) => (
                  <div key={campaign.id}>
                    <div className='grid gid-cols-1-gap4 mb-3'>
                      <div className='bg-gray-200 p-4 rounded-lg shadow-lg'>
                        <div className='flex justify-between'>
                          <div className='flex flex-col items-start text-sm justify-between'>
                            <div className='flex flex-col text-md  text-left mr-3'>
                              <p className='font-bold text-md'>{campaign.campaignName}</p>

                              {campaign.campaignType === 'Spend X Amount To Earn a Stamp' ? (
                                <>
                                  {campaign.spendPerStamp && (
                                    <span className='text-xs text-gray-500'>
                                      Required spend per stamp {user.currency}
                                      {campaign.spendPerStamp.toFixed(2)}
                                    </span>
                                  )}
                                </>
                              ) : null}
                            </div>

                            {countIsLoading ? (
                              <div className='flex'>
                                <img className='' src='/images/loadingSpinner.svg' alt='Loading...' /> <p>&nbsp;Counting & Verifying Stamps...</p>
                              </div>
                            ) : (
                              <p className='font-bold  pt-5 text-xs md:text-base'>
                                {campaign.newStamps} out of {campaign.goal} Stamps
                              </p>
                            )}

                            {campaign.readyToRedeem > 0 && (
                              <p className='font-bold text-md pt-5'>
                                {campaign.readyToRedeem > 1 ? `${campaign.readyToRedeem} Rewards are` : `${campaign.readyToRedeem} Reward is`} ready
                                to redeem
                              </p>
                            )}
                          </div>
                          <div className='flex flex-col items-end'>
                            <div className=''>
                              {campaign.campaignType === 'Spend X Amount To Earn a Stamp' && (
                                <div className='flex mb-1'>
                                  {(() => {
                                    const shouldReset = shouldResetAccumulation(customer.client?.dateOfLastVisit, campaign.resetAccumulationAfter)
                                    return (
                                      !shouldReset && (
                                        <p className='text-xs mt-1'>
                                          Current spend is {''}
                                          <span className='font-bold'>
                                            {user.currency}
                                            {`${campaign.currentSpendTowardsStamp}`}
                                          </span>
                                        </p>
                                      )
                                    )
                                  })()}
                                </div>
                              )}
                              {campaign.value === true && (
                                <div className='relative inline-block'>
                                  <input
                                    type='number'
                                    placeholder='Add Order Value'
                                    className={`input w-40 pl-5 text-xs md:text-base ${
                                      campaign.campaignType === 'Spend X Amount To Earn a Stamp' ? 'mb-0' : 'mb-3'
                                    }`}
                                    required={campaign.compulsory === true}
                                    value={orderValues[campaign.id] || ''}
                                    onChange={(e) => {
                                      const orderValue = parseFloat(e.target.value)
                                      setOrderValues({ ...orderValues, [campaign.id]: e.target.value })
                                      if (campaign.campaignType === 'Spend X Amount To Earn a Stamp') {
                                        // Calculate the total spend and the number of stamps to be awarded
                                        const totalSpend = campaign.currentSpendTowardsStamp + orderValue
                                        const stamps = Math.floor(totalSpend / campaign.spendPerStamp)
                                        setStampsToBeAwarded({ ...stampsToBeAwarded, [campaign.id]: stamps })
                                      }
                                    }}
                                  />

                                  <span className='pound-symbol absolute top-0 left-0 mt-3 ml-2'>{user.currency}</span>
                                </div>
                              )}

                              {campaign.campaignType === 'Spend X Amount To Earn a Stamp' && (
                                <div className='flex mb-1'>
                                  {(() => {
                                    const shouldReset = shouldResetAccumulation(customer.client?.dateOfLastVisit, campaign.resetAccumulationAfter)

                                    return (
                                      !shouldReset && (
                                        <div className='flex'>
                                          <p className='text-xs mt-1'>
                                            Spend{' '}
                                            <span className='font-bold'>
                                              {user.currency}
                                              {campaign.spendPerStamp - campaign.currentSpendTowardsStamp >= 0
                                                ? (campaign.spendPerStamp - campaign.currentSpendTowardsStamp).toFixed(2)
                                                : '0.00'}
                                            </span>{' '}
                                            for next stamp
                                          </p>
                                        </div>
                                      )
                                    )
                                  })()}
                                </div>
                              )}
                            </div>

                            <button
                              onClick={() => handleApplyStamp(campaign.id)}
                              className='w-40 btn mb-3 btn-primary md:text-base mt-3'
                              disabled={
                                applyStampButtonDisabled ||
                                countIsLoading ||
                                (campaign.compulsory === true && (orderValues[campaign.id] || '').trim() === '')
                              }>
                              {stampIsLoading ? (
                                <>
                                  applying Stamp
                                  <img className='text-white' src='/images/loadingSpinner.svg' alt='Loading...' />
                                </>
                              ) : campaign.campaignType === 'Spend X Amount To Earn a Stamp' ? (
                                <>
                                  <span className=''>Place Order</span>
                                  {stampsToBeAwarded[campaign.id] === 1 ? (
                                    <div className='text-xs  text-white'>(1 stamp earned)</div>
                                  ) : stampsToBeAwarded[campaign.id] > 1 ? (
                                    <div className='text-xs  text-white'>({stampsToBeAwarded[campaign.id]} stamps earned)</div>
                                  ) : (
                                    <div className='text-xs text-'>(no stamps earned)</div>
                                  )}
                                </>
                              ) : (
                                'Apply Stamp'
                              )}
                            </button>
                            {campaign.readyToRedeem > 0 && (
                              <button
                                onClick={() => handleRedeemCoupon(campaign.id)}
                                className='btn btn-secondary w-40'
                                disabled={redeemButtonDisabled || countIsLoading}>
                                {redeemIsLoading ? <img src='/images/loadingSpinner.svg' alt='Loading...' /> : 'Redeem Coupon'}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}
          {customerCampaigns && customerCampaigns.length > 0 && campaigns.length === 0 && (
            <div className='flex justify-center'>
              <h1>Join customer to another campaign?</h1>{' '}
              <button className='btn btn-primary btn-sm ml-3' onClick={fetchExistingCampaigns}>
                {' '}
                Fetch Campaigns
              </button>
            </div>
          )}
          {campaignsFetched && campaigns.length > 0 && Array.isArray(customerCampaigns) && (
            <div className='grid gid-cols-1-gap4 mb-3 mt-8'>
              {/* Check if there are any campaigns that the customer hasn't joined yet */}
              {campaigns.some(
                (campaign) =>
                  new Date(campaign.startDate) <= new Date() && !customerCampaigns.some((joinedCampaign) => joinedCampaign.id === campaign._id)
              ) ? (
                <>
                  <h1 className='font-bold mb-3'> {customer.name} is eligible to join the following campaigns:</h1>
                  {campaigns
                    .filter(
                      (campaign) =>
                        new Date(campaign.startDate) <= new Date() && !customerCampaigns.some((joinedCampaign) => joinedCampaign.id === campaign._id)
                    )
                    .map((campaign) => (
                      <div key={campaign._id} className='bg-gray-200 p-3 mb-3 rounded-lg shadow-lg'>
                        <div className='flex justify-between items-center space-x-2 text-sm'>
                          <div className='text-md font-bold text-left'>{campaign.campaignName}</div>
                          <button onClick={() => joinToCampaign(campaign)} className='btn'>
                            Join
                          </button>
                        </div>
                      </div>
                    ))}
                </>
              ) : null}
            </div>
          )}

          <div className='modal-action'>
            <label htmlFor='my-modal-6' className='btn' onClick={() => resetCustomerData()}>
              Close
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QRreaderDesktop
