import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getReferFriendMessages, reset } from '../features/message/messagesSlice'
import Spinner from '../components/Spinner'
import { customToast } from '../components/CustomToast'
import { useNavigate } from 'react-router-dom'
import SkeletonRow from '../components/SkeletonRow'

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text
  return text.substring(0, maxLength) + '...'
}

const ReferAFriendMessages = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isLoading, isSuccess, isError, message, referFriendMessages } = useSelector((state) => state.messages)

  useEffect(() => {
    dispatch(getReferFriendMessages())
  }, [dispatch])

  useEffect(() => {
    if (isSuccess) {
      dispatch(reset())
      dispatch(getReferFriendMessages()) // Fetch messages again when there's a success
    }

    if (isError) {
      customToast.error(message.message)
      dispatch(reset())
    }
  }, [dispatch, isSuccess, isError, message])

  return (
    <div className='w-full'>
      <table className='table w-full'>
        <thead>
          <tr>
            <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Message Type</th>
            <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Message Content</th>
            <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Last Modified</th>
            <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            Array(5)
              .fill(0)
              .map((_, index) => <SkeletonRow key={index} columns={4} />)
          ) : referFriendMessages ? (
            <>
              {referFriendMessages.email && (
                <tr>
                  <td className='table-optimized font-bold'>Email</td>
                  <td className='table-optimized'>{truncateText(referFriendMessages.email, 50)}</td>
                  <td className='table-optimized'>
                    {new Date(referFriendMessages.updatedAt)
                      .toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })
                      .split(',')
                      .join(' ')}
                  </td>
                  <td className='table-optimized'>
                    <button className='btn-small' onClick={() => navigate(`/editreferfriendmessage/email`)}>
                      Edit
                    </button>
                  </td>
                </tr>
              )}
              {referFriendMessages.WhatsApp && (
                <tr>
                  <td className='table-optimized font-bold'>WhatsApp</td>
                  <td className='table-optimized'>{truncateText(referFriendMessages.WhatsApp, 50)}</td>
                  <td className='table-optimized'>
                    {new Date(referFriendMessages.updatedAt)
                      .toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })
                      .split(',')
                      .join(' ')}
                  </td>
                  <td className='table-optimized'>
                    <button className='btn-small' onClick={() => navigate(`/editreferfriendmessage/WhatsApp`)}>
                      Edit
                    </button>
                  </td>
                </tr>
              )}
              {referFriendMessages.Twitter && (
                <tr>
                  <td className='table-optimized font-bold'>Twitter</td>
                  <td className='table-optimized'>{truncateText(referFriendMessages.Twitter, 50)}</td>
                  <td className='table-optimized'>
                    {new Date(referFriendMessages.updatedAt)
                      .toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })
                      .split(',')
                      .join(' ')}
                  </td>
                  <td className='table-optimized'>
                    <button className='btn-small' onClick={() => navigate(`/editreferfriendmessage/Twitter`)}>
                      Edit
                    </button>
                  </td>
                </tr>
              )}
              {referFriendMessages.LinkedIn && (
                <tr>
                  <td className='table-optimized font-bold'>LinkedIn</td>
                  <td className='table-optimized'>{truncateText(referFriendMessages.LinkedIn, 50)}</td>
                  <td className='table-optimized'>
                    {new Date(referFriendMessages.updatedAt)
                      .toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })
                      .split(',')
                      .join(' ')}
                  </td>
                  <td className='table-optimized'>
                    <button className='btn-small' onClick={() => navigate(`/editreferfriendmessage/LinkedIn`)}>
                      Edit
                    </button>
                  </td>
                </tr>
              )}
              {referFriendMessages.Telegram && (
                <tr>
                  <td className='table-optimized font-bold'>Telegram</td>
                  <td className='table-optimized'>{truncateText(referFriendMessages.Telegram, 50)}</td>
                  <td className='table-optimized'>
                    {new Date(referFriendMessages.updatedAt)
                      .toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })
                      .split(',')
                      .join(' ')}
                  </td>
                  <td className='table-optimized'>
                    <button className='btn-small' onClick={() => navigate(`/editreferfriendmessage/Telegram`)}>
                      Edit
                    </button>
                  </td>
                </tr>
              )}
            </>
          ) : (
            <tr>
              <td colSpan={4}>No messages found.</td>
            </tr>
          )}
        </tbody>

        <tfoot>
          <tr>
            <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Message Type</th>
            <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Message Content</th>
            <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Last Modified</th>
            <th className='text-xxxs lg:text-xs md:text-base pl-4 px-1'>Actions</th>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default ReferAFriendMessages
