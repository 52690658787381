import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import html2pdf from 'html2pdf.js'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { fetchImage, fetchLogoImage } from '../features/customer/customersSlice'
import { getUserDetails } from '../features/auth/authSlice'
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
import WebFont from 'webfontloader'

const Flyer = () => {
  const { user, userDetails } = useSelector((state) => state.auth)
  const [selectedHeadline, setSelectedHeadline] = useState('Loyalty Pays Off: Join Our Club for Exclusive Discounts & Offers!')
  const [headlineColor, setHeadlineColor] = useState('#000000')
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [customHeadline, setCustomHeadline] = useState('')
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false)
  const { imageUrl, logoImageUrl } = useSelector((state) => state.customers)
  const [selectedFont, setSelectedFont] = useState('Roboto')
  const [showLogo, setShowLogo] = useState(true)
  const dispatch = useDispatch()

  const loadFont = (fontFamily) => {
    WebFont.load({
      google: {
        families: [fontFamily],
      },
    })
  }

  const styles = reactCSS({
    default: {
      color: {
        width: '36px',
        height: '36px',
        borderRadius: '2px',
        background: headlineColor,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
        marginRight: '12px',
        marginLeft: '4px',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
        top: '240px',
        right: '0px',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  })

  const footerBarStyle = {
    backgroundColor: '#8c8c8c',
    color: '#fff',
    textAlign: 'center',
    padding: '10px',
    marginTop: '20px',
    paddingBottom: '30px',
    position: 'absolute',
    bottom: '0',
    width: '100%',
    fontSize: '28px',
    fontFamily: selectedFont,
  }

  const flyerContentStyle = {
    width: '210mm',
    height: '297mm',
    backgroundColor: 'white',
    position: 'relative',
    display: 'flex',
    paddingRight: '40px',
    paddingLeft: '40px',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '20mm 0mm',
  }

  const isSvg = (qrCode) => {
    return qrCode && qrCode.includes('<svg')
  }

  useEffect(() => {
    dispatch(getUserDetails())
  }, [dispatch])

  useEffect(() => {
    if (userDetails?.qr_code && !isSvg(userDetails.qr_code)) {
      dispatch(fetchImage(userDetails.qr_code))
    }
  }, [dispatch, userDetails])

  useEffect(() => {
    if (userDetails?.logo) {
      dispatch(fetchLogoImage(userDetails.logo))
    }
  }, [userDetails, dispatch])

  useEffect(() => {
    loadFont(selectedFont)
  }, [selectedFont])

  const headlines = [
    'Scan for Stellar Savings: Your Adventure Awaits!',
    'Brighten Your Day: Tap into Exclusive Rewards with a Scan!',
    'Enter the Savings Zone: One Quick Scan and You Will Shine!',
    'Your VIP Pass to Unbelievable Deals Awaits Your Scan!',
    'Get Rewarded Instantly: A Simple Scan Brings Big Surprises!',
    'Ready for a Deal Delight? Let Your Scan Do the Magic!',
    'Unlock the Treasure Trove of Discounts With a Single Scan!',
    'Zap into Savings: Harness the Power of a Quick QR Scan!',
    'Jumpstart Your Joyride: Scan Into a World of Discounts!',
    'Rev Up Your Rewards: Let a Scan Ignite the Savings!',
    'The Scanning Revolution: Blaze a Trail to Exclusive Perks!',
    'Discover Your Destiny: A QR Code Away from Fabulous Deals!',
    'Let the Savings Soar: One Awesome Scan is All It Takes!',
    'Make It Happen: Your Exciting Journey to Rewards Begins with a Scan!',
    'Elevate Your Experience: Embrace the Future of Deals Through a Scan!',
    'Step Into Brilliance: Uncover a World of Discounts With One Scan!',
    'Let the Magic of Savings Unfold: Your Scan is the Key!',
    'Experience Wonder: Join the Loyalty League with a Swift Scan!',
    'Ride the Wave of Rewards: Your QR Code Unlocks the Fun!',
    'Transform Your Moments: One Scan, Infinite Surprises!',
  ]

  const exportToPDF = () => {
    setIsGeneratingPDF(true)
    const element = document.getElementById('flyer-content')
    const options = {
      filename: 'flyer.pdf',
      margin: [0, 0],
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2,
        width: element.offsetWidth,
        height: element.offsetHeight,
        windowWidth: element.offsetWidth,
        windowHeight: element.offsetHeight,
        useCORS: true,
      },
      jsPDF: {
        unit: 'mm',
        format: 'a4',
        orientation: 'portrait',
      },
    }
    html2pdf()
      .from(element)
      .set(options)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = totalPages; i > 1; i--) {
          pdf.deletePage(i)
        }
        pdf.save(options.filename)
        setIsGeneratingPDF(false)
      })
  }

  const handleColorChange = (color) => {
    setHeadlineColor(color.hex)
  }

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker)
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Marketing Resources | Loyalty Club PLC</title>
        </Helmet>
      </HelmetProvider>
      <div className='container mx-auto'>
        <div className='text-center mb-6'>
          <h2 className='text-2xl font-semibold mb-2'>Create Your Loyalty Club Poster</h2>
          <p className='text-gray-600'>
            Design a custom poster to display in your store, inviting customers to join your loyalty scheme by scanning the QR code. Customize the
            headline and choose from a variety of fonts to create a simple poster to display inside of your establishment.
          </p>
        </div>
        <div className='mb-4'>
          <label className='flex items-center'>
            <input type='checkbox' checked={showLogo} onChange={() => setShowLogo((prev) => !prev)} className='mr-2' />
            Show Company Logo
          </label>
          {!userDetails?.logo ? (
            <p className='text-left italic text-red-500'>
              You don't currently have a logo for your business, please add one in your{' '}
              <Link className='underline' to='/settings'>
                Account{' '}
              </Link>{' '}
            </p>
          ) : null}
        </div>
        <h2 className='text-xl font-semibold mb-4'>Customize Headline</h2>
        <p className='text-left pb-2'>Select a headline</p>
        {/* Container */}
        <div className='flex md:width-1/2 items-center mb-4'>
          {/* Select Headline */}
          <div className='flex flex-row mr-12 flex-grow form-control'>
            <select
              className='select select-bordered w-full'
              value={selectedHeadline}
              onChange={(e) => {
                setSelectedHeadline(e.target.value)
                setCustomHeadline(e.target.value) // Add this line
              }}>
              <option value=''>-- Select a headline --</option>
              {headlines.map((headline, index) => (
                <option key={index} value={headline}>
                  {headline}
                </option>
              ))}
            </select>
            {/* Select color */}
            <div className='ml-1'>
              <div style={styles.swatch} onClick={toggleColorPicker}>
                <div style={styles.color} />
              </div>
              {showColorPicker ? (
                <div style={styles.popover}>
                  <div style={styles.cover} onClick={toggleColorPicker} />
                  <SketchPicker
                    colors={['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#000000']}
                    color={headlineColor}
                    onChange={handleColorChange}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* Custom Headline */}
        <div className='mb-4'>
          <p className='text-left pb-2'>Either customize or create your own headline </p>
          <input
            type='text'
            placeholder='Enter custom headline'
            className='input input-bordered w-full'
            value={customHeadline}
            onChange={(e) => {
              setCustomHeadline(e.target.value)
              setSelectedHeadline(e.target.value)
            }}
          />
        </div>
        <div className='mb-3'>
          {/* Select font */}
          <p className='flex mb-3'> Pick a font</p>
          <select
            className='select select-bordered w-full mb-3'
            value={selectedFont}
            onChange={(e) => {
              setSelectedFont(e.target.value)
            }}>
            <option value='Roboto'>Roboto</option>
            <option value='Open Sans'>Open Sans</option>
            <option value='Lato'>Lato</option>
            <option value='Montserrat'>Montserrat</option>
            <option value='Source Sans Pro'>Source Sans Pro</option>
            <option value='Oswald'>Oswald</option>
            <option value='Raleway'>Raleway</option>
            <option value='Poppins'>Poppins</option>
            <option value='Noto Sans'>Noto Sans</option>
            <option value='Merriweather'>Merriweather</option>
            <option value='PT Sans'>PT Sans</option>
            <option value='Roboto Condensed'>Roboto Condensed</option>
            <option value='Ubuntu'>Ubuntu</option>
            <option value='Fira Sans'>Fira Sans</option>
            <option value='Roboto Slab'>Roboto Slab</option>
            <option value='Playfair Display'>Playfair Display</option>
            <option value='Nunito'>Nunito</option>
            <option value='Muli'>Muli</option>
            <option value='Quicksand'>Quicksand</option>
            <option value='Lora'>Lora</option>
            <option value='Titillium Web'>Titillium Web</option>
            <option value='Noto Serif'>Noto Serif</option>
            <option value='Work Sans'>Work Sans</option>
            <option value='Exo 2'>Exo 2</option>
            <option value='Zilla Slab'>Zilla Slab</option>
            <option value='PT Serif'>PT Serif</option>
            <option value='Arvo'>Arvo</option>
            <option value='Heebo'>Heebo</option>
            <option value='Rubik'>Rubik</option>
            <option value='Karla'>Karla</option>
            <option value='Archivo'>Archivo</option>
            <option value='Abel'>Abel</option>
            <option value='IBM Plex Sans'>IBM Plex Sans</option>
            <option value='Josefin Sans'>Josefin Sans</option>
            <option value='Libre Baskerville'>Libre Baskerville</option>
            <option value='Cabin'>Cabin</option>
            <option value='Old Standard TT'>Old Standard TT</option>
            <option value='Cormorant Garamond'>Cormorant Garamond</option>
            <option value='Alegreya'>Alegreya</option>
            <option value='Crimson Text'>Crimson Text</option>
            <option value='Hind'>Hind</option>
            <option value='Amatic SC'>Amatic SC</option>
            <option value='Inconsolata'>Inconsolata</option>
            <option value='Anton'>Anton</option>
            <option value='Dosis'>Dosis</option>
            <option value='Bitter'>Bitter</option>
            <option value='Dancing Script'>Dancing Script</option>
            <option value='Orbitron'>Orbitron</option>
            <option value='Catamaran'>Catamaran</option>
          </select>
        </div>

        {/* Flyer content*/}
        <div className='flex justify-center border rounded-md '>
          <div id='flyer-content' className='mb-4  w-full h-auto relative' style={flyerContentStyle}>
            {/* Logo */}
            {showLogo && (
              <div className='flex justify-center'>
                {logoImageUrl ? (
                  <img src={logoImageUrl} className='w-36 h-36' alt='logo' />
                ) : (
                  <div className='flex justify-center items-center w-36 h-36'>
                    <svg className='animate-spin h-16 w-16 text-orange-500' viewBox='0 0 24 24'>
                      <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
                      <path className='opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'></path>
                    </svg>
                  </div>
                )}
              </div>
            )}
            {/* Headline */}
            <h1
              className={`text-7xl font-bold ${!isGeneratingPDF ? 'headline-mobile' : ''}`}
              style={{
                textAlign: 'center',
                width: '100%',
                color: headlineColor,
                fontFamily: selectedFont,
                marginLeft: '40mm', // added left margin
                marginRight: '40mm', // added right margin
              }}>
              {selectedHeadline || customHeadline}
            </h1>
            {/* QR Code */}
            <div className='flex justify-center items-center mr-20'>
              <div className='flex flex-col items-center'>
                <h2 className='text-2xl font-bold mb-2' style={{ color: '#000000', fontFamily: selectedFont }}>
                  Scan Me
                </h2>
                <img className='w-[50px] h-[50px] ml-11' src='/images/curved-arrow.png' alt='arrow' />
              </div>

              {imageUrl ? (
                <img className='mx-auto w-72 h-72 rounded-2xl mb-15' src={imageUrl} alt='' />
              ) : (
                <div className='flex justify-center items-center w-72 h-72'>
                  <svg className='animate-spin h-16 w-16 text-orange-500' viewBox='0 0 24 24'>
                    <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
                    <path className='opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'></path>
                  </svg>
                </div>
              )}
            </div>
            {/* Footer Bar */}
            <div className='flex items-center justify-center' style={footerBarStyle}>
              <span className='mr-2'>Powered By</span>
              <img className='w-48' src='/images/logo-dark.png' alt='Logo' />
            </div>
          </div>
        </div>
        {/* Download PDF button */}
        <button className='btn btn-secondary mb-20 mt-3' onClick={exportToPDF} disabled={!selectedHeadline}>
          Download PDF
        </button>
      </div>
    </>
  )
}

export default Flyer
