import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { customToast } from '../components/CustomToast'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import Spinner from '../components/Spinner'
import { getCustomers, reset } from '../features/customer/customersSlice'
import Pagination from '../components/Pagination'
import { useDebounce } from '../components/useDebounce'
import SkeletonRow from '../components/SkeletonRow'

const Customers = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const { customers, isError, isSuccess, isLoading, message } = useSelector((state) => state.customers)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    if (isError) {
      customToast.error(message)
    }
    if (isSuccess) {
      dispatch(reset())
    }
  }, [isSuccess, dispatch, isError, message])

  useEffect(() => {
    const filterData = {
      page: currentPage,
      limit: 20,
      searchTerm: debouncedSearchTerm,
    }
    dispatch(getCustomers(filterData))

    return () => {
      dispatch(reset())
    }
  }, [dispatch, currentPage, debouncedSearchTerm])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setCurrentPage(1)
  }

  // if (isLoading) {
  //   return <Spinner />
  // }

  return (
    <div>
      <Helmet>
        <title>Customers | Loyalty Club PLC</title>
      </Helmet>
      <div className='flex flex-col md:flex-row mb-8 justify-between mt-2'>
        <Link to='/addcustomer' className='btn btn-primary text-xs md:text-sm md:mr-20'>
          Add New Customer
        </Link>
        <input
          type='text'
          placeholder='Search for a customer by name or email'
          value={searchTerm}
          onChange={handleSearchChange}
          className='input w-full  text-xs md:text-md mt-3 md:mt-0'
        />
      </div>
      <div className='overflow-x-auto pb-20'>
        <div className='table-responsive'>
          <table className='table mb-16 w-full min-w-full shadow-lg table-zebra'>
            <thead>
              <tr>
                <th className='text-xxxs md:text-xs lg:text-base pl-4 px-4'>Customer Name</th>
                <th className='text-xxxs md:text-xs lg:text-base px-4 '>Date Joined</th>

                <th className='text-xxxs md:text-xs lg:text-base px-4'>Last Visit</th>
                <th className='text-xxxs md:text-xs lg:text-base px-4'>Number of Visits</th>
                <th className='text-xxxs md:text-xs lg:text-base px-4'>
                  <span className='block md:hidden'>
                    Friends
                    <br />
                    Referred
                  </span>
                  <span className='hidden md:block'>Friends Referred</span>
                </th>
                <th className='text-xxxs lg:text-xs md:text-base px-1'>GPDR</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                Array(10)
                  .fill(0)
                  .map((_, index) => <SkeletonRow key={index} />)
              ) : !Array.isArray(customers.customers) || customers.customers.length === 0 ? (
                <tr>
                  <td colSpan='6'>No customers found.</td>
                </tr>
              ) : (
                customers.customers.map((customer, index) => (
                  <tr className={`${index % 2 === 0 ? 'bg-base-200' : ''} text-xxxs md:text-xs`} key={customer.id}>
                    <td className='table-optimized'>
                      <h1 className='pb-1 md:pb-2 text-xxs md:text-base lg:text-base'>{customer.name}</h1>
                      <Link className='btn btn-secondary btn-xs md:btn-sm text-xxs shadow-xl lg:text-md' to={`/customer/${customer.id}`}>
                        View
                      </Link>
                    </td>
                    <td className='table-optimized'>{new Date(customer.client.dateJoinedClient).toLocaleDateString()}</td>
                    <td className='table-optimized'>
                      {customer.client.dateOfLastVisit ? new Date(customer.client.dateOfLastVisit).toLocaleDateString() : 'No visits yet'}
                    </td>
                    <td className='table-optimized'>{customer.client.numberOfVisits}</td>
                    <td className='table-optimized'>{customer.client.friendsSuccessfullyIntroduced.length}</td>
                    <td className='table-optimized'>{customer.client.acceptedGPDR ? 'Yes' : 'No'}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      {customers.totalPages > 1 && <Pagination totalPages={customers.totalPages} currentPage={currentPage} onPageChange={handlePageChange} />}
    </div>
  )
}

export default Customers
