import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reset as resetAuth } from '../features/auth/authSlice'
import { CountryDropdown } from 'react-country-region-selector'
import { customToast } from './CustomToast'
import { reset } from '../features/payments/paymentsSlice'

const SquarePaymentButton = ({ onPaymentSuccess, amount, currency, productId, setShowPaymentForm }) => {
  const dispatch = useDispatch()
  const [payments, setPayments] = useState(null)
  const [card, setCard] = useState(null)
  const [paymentStatus, setPaymentStatus] = useState('')
  const { loading, isError, message, isSuccess, products, isLoading } = useSelector((state) => state.payments)
  const { userDetails, isError: authError, message: authMessage } = useSelector((state) => state.auth)

  const { REACT_APP_SQUARE_APPLICATION_ID, REACT_APP_SQUARE_LOCATION_ID } = process.env

  const { registrationDetails } = useSelector((state) => state.auth)

  const [cardholderDetails, setCardholderDetails] = useState({
    cardholderName: '',
    addressLine1: '',
    addressLine2: '',
    locality: '',
    administrativeDistrictLevel1: '',
    country: 'GB', // Default to GB
  })

  const [matchedProduct, setMatchedProduct] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setCardholderDetails((prev) => ({ ...prev, [name]: value }))
  }

  const handleCountryChange = (val) => {
    setCardholderDetails((prev) => ({ ...prev, country: val }))
  }

  useEffect(() => {
    const loadSquareSDK = async () => {
      const script = document.createElement('script')
      script.src = process.env.REACT_APP_SQUARE_SDK_URL
      script.async = true
      script.onload = () => initializeSquare()
      document.body.appendChild(script)
    }

    const initializeSquare = async () => {
      try {
        const payments = window.Square.payments(REACT_APP_SQUARE_APPLICATION_ID, REACT_APP_SQUARE_LOCATION_ID)
        if (!payments) {
          throw new Error('Square payments object is undefined')
        }
        setPayments(payments)

        const cardInstance = await payments.card()
        await cardInstance.attach('#card-container')
        setCard(cardInstance)
      } catch (error) {
        console.error('Error initializing Square Payments:', error)
      }
    }

    loadSquareSDK()
  }, [])

  useEffect(() => {
    const findMatchingProduct = () => {
      const matched = products.find((product) =>
        product.subscriptionPlanData.subscriptionPlanVariations.some((variation) => variation.id === productId)
      )
      setMatchedProduct(matched)
    }

    if (products && productId) {
      findMatchingProduct()
    }
  }, [products, productId])

  const handlePayment = async (e) => {
    e.preventDefault()
    setIsProcessing(true)
    try {
      if (!card || !payments) {
        throw new Error('Card payment method not initialized')
      }
      const tokenResult = await card.tokenize()
      if (tokenResult.status === 'OK') {
        const paymentData = {
          token: tokenResult.token,
          squareCustomerId: registrationDetails.subscription.squareCustomerID,
          productId: productId,
          productData: matchedProduct,
          addressDetails: registrationDetails.address,
          customerId: registrationDetails.customerId,
          cardholderName: cardholderDetails.cardholderName,
          billingAddress: {
            addressLine1: cardholderDetails.addressLine1,
            addressLine2: cardholderDetails.addressLine2,
            locality: cardholderDetails.locality,
            administrativeDistrictLevel1: cardholderDetails.administrativeDistrictLevel1,
            country: cardholderDetails.country,
          },
          customerName: registrationDetails.name,
          verificationToken: await payments.verifyBuyer(tokenResult.token, {
            billingContact: {
              givenName: cardholderDetails.cardholderName.split(' ')[0],
              familyName: cardholderDetails.cardholderName.split(' ').slice(1).join(' '),
              email: registrationDetails.email,
              phone: registrationDetails.phone,
              address: {
                address_line_1: cardholderDetails.addressLine1,
                address_line_2: cardholderDetails.addressLine2 || undefined,
                locality: cardholderDetails.locality,
                administrative_district_level_1: cardholderDetails.administrativeDistrictLevel1,
                postal_code: tokenResult.details?.billing?.postalCode,
                country: cardholderDetails.country,
              },
            },
            currencyCode: currency,
            intent: 'STORE',
          }),
        }
        onPaymentSuccess(paymentData)
      } else {
        throw new Error(tokenResult.errors[0].message)
      }
    } catch (error) {
      const errorMessage = `Payment failed: ${error.message}. Please try again or contact support.`
      setPaymentStatus(errorMessage)
      console.log('errorMessage', errorMessage)
      customToast.error(errorMessage)
    } finally {
      setIsProcessing(false)
    }
  }

  useEffect(() => {
    if (
      authError &&
      (authMessage?.message === 'A business of this type already exists in the postcode area.' ||
        authMessage?.message === 'An account with this email address already exists. Please try logging in or use a different email address.')
    ) {
      setShowPaymentForm(true)
      dispatch(resetAuth())
    }
  }, [authError, authMessage, dispatch, setShowPaymentForm])

  useEffect(() => {
    console.log('there is an error ')
    if (isError && message) {
      const errorMessage = message
      customToast.error(errorMessage)
    }
    dispatch(reset())
  }, [isError, message])

  return (
    <div>
      {(isProcessing || isLoading) && (
        <div className='flex flex-col items-center justify-center space-y-4 min-h-[200px]'>
          <div className='w-12 h-12 border-4 border-primary border-t-transparent rounded-full animate-spin'></div>
          <p className='text-gray-500'>{isLoading ? 'Setting up your subscription...' : 'Processing Payment, please wait...'}</p>
        </div>
      )}

      <form id='payment-form' onSubmit={handlePayment}>
        <div className='form-group relative mb-2'>
          <input
            type='text'
            className={`global-input peer ${isProcessing || isLoading ? 'bg-gray-100 cursor-not-allowed opacity-60' : ''}`}
            placeholder=' '
            id='cardholderName'
            name='cardholderName'
            value={cardholderDetails.cardholderName}
            onChange={handleInputChange}
            required
            disabled={isProcessing || isLoading}
          />
          <label
            htmlFor='cardholderName'
            className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
            Cardholder Name
          </label>
        </div>

        <div className='form-group relative mb-2'>
          <input
            type='text'
            className={`global-input peer ${isProcessing || isLoading ? 'bg-gray-100 cursor-not-allowed opacity-60' : ''}`}
            placeholder=' '
            id='addressLine1'
            name='addressLine1'
            value={cardholderDetails.addressLine1}
            onChange={handleInputChange}
            required
            disabled={isProcessing || isLoading}
          />
          <label
            htmlFor='addressLine1'
            className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
            Address Line 1
          </label>
        </div>

        <div className='form-group relative mb-2'>
          <input
            type='text'
            className={`global-input peer ${isProcessing || isLoading ? 'bg-gray-100 cursor-not-allowed opacity-60' : ''}`}
            placeholder=' '
            id='addressLine2'
            name='addressLine2'
            value={cardholderDetails.addressLine2}
            onChange={handleInputChange}
            disabled={isProcessing || isLoading}
          />
          <label
            htmlFor='addressLine2'
            className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
            Address Line 2
          </label>
        </div>

        <div className='form-group relative mb-2'>
          <input
            type='text'
            className={`global-input peer ${isProcessing || isLoading ? 'bg-gray-100 cursor-not-allowed opacity-60' : ''}`}
            placeholder=' '
            id='locality'
            name='locality'
            value={cardholderDetails.locality}
            onChange={handleInputChange}
            required
            disabled={isProcessing || isLoading}
          />
          <label
            htmlFor='locality'
            className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
            City
          </label>
        </div>

        <div className='form-group relative mb-2'>
          <input
            type='text'
            className={`global-input peer ${isProcessing || isLoading ? 'bg-gray-100 cursor-not-allowed opacity-60' : ''}`}
            placeholder=' '
            id='administrativeDistrictLevel1'
            name='administrativeDistrictLevel1'
            value={cardholderDetails.administrativeDistrictLevel1}
            onChange={handleInputChange}
            required
            disabled={isProcessing || isLoading}
          />
          <label
            htmlFor='administrativeDistrictLevel1'
            className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
            State/Province
          </label>
        </div>

        <div className='form-group relative mb-2'>
          <CountryDropdown
            value={cardholderDetails.country}
            onChange={handleCountryChange}
            className={`global-input-select ${isProcessing || isLoading ? 'bg-gray-100 cursor-not-allowed opacity-60' : ''}`}
            defaultOptionLabel='Select country'
            priorityOptions={['GB', 'US', 'CA']}
            showDefaultOption={true}
            valueType='short'
            placeholder=' '
            disabled={isProcessing || isLoading}
          />
          <label
            htmlFor='country'
            className='global-form-label transition-all duration-200 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-sm peer-focus:pt-1 peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:-translate-y-0 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:pt-1'>
            Country
          </label>
        </div>

        <div id='card-container' className={`mb-4 ${isProcessing || isLoading ? 'opacity-60' : ''}`}></div>
        <button
          type='submit'
          disabled={loading || isProcessing || isLoading}
          className={`btn-primary flex p-6 rounded-md text-white w-full justify-center items-center space-x-4 font-sans font-bold shadow-lg px-9 hover:bg-opacity-90 hover:shadow-lg border transition hover:-translate-y-0.5 duration-150 ${
            loading || isProcessing || isLoading ? 'opacity-60 cursor-not-allowed' : ''
          }`}>
          Pay {amount / 100} {currency} and Start Subscription
        </button>
      </form>

      {/* {paymentStatus && <p className='mt-4'>{paymentStatus}</p>} */}
      {isError && <p className='mt-4 text-red-500'>Error: {message}</p>}
      {isSuccess && <p className='mt-4 text-green-500'>Payment successful and subscription started!</p>}
      <div id='payment-status-container'></div>
      <p className='text-sm text-gray-600 mt-2'>
        If you're experiencing issues, please ensure any ad blockers or content blockers are disabled for this site.
      </p>
      {matchedProduct && <p className='mt-4 text-sm text-gray-600'>Selected plan: {matchedProduct.subscriptionPlanData.name}</p>}
    </div>
  )
}

export default SquarePaymentButton
