import React, { useState } from 'react'
import { convertFromRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { useSelector, useDispatch } from 'react-redux'
import { postMessageToSegment, updateMessage } from '../../features/message/messagesSlice'
import moment from 'moment'
import 'react-datetime/css/react-datetime.css'
import Datetime from 'react-datetime'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { customToast } from '../CustomToast'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import '../../editor-styles.css'

const Summary = ({ onChange, campaignData, setCurrentStep, messageID }) => {
  const navigate = useNavigate()
  const { campaignName, customerSegment, messageSubject, messageBody, dateTime } = campaignData

  const [isScheduled, setIsScheduled] = useState(moment(dateTime).isAfter(moment()))
  const dispatch = useDispatch()

  const renderRichText = (content) => {
    if (typeof content === 'object' && content.blocks) {
      const html = draftToHtml(content)
      return <div dangerouslySetInnerHTML={{ __html: html }} />
    } else if (typeof content === 'string') {
      return <div dangerouslySetInnerHTML={{ __html: content }} />
    }
    return content || ''
  }

  const handleEditMessage = () => {
    setCurrentStep(3)
  }

  const handleToggleSchedule = () => {
    setIsScheduled(!isScheduled)
  }

  const handleSendMessage = () => {
    let messageData = {
      customerSegment,
      messageSubject,
      messageBody,
      campaignName,
    }

    if (isScheduled) {
      messageData = {
        ...messageData,
        scheduledTime: dateTime,
      }
    }

    if (messageID) {
      messageData = {
        ...messageData,
        messageID,
      }
    }

    if (messageID) {
      // Update the existing message if messageID exists
      dispatch(updateMessage(messageData)).then(() => {
        navigate('/messagecampaigns')
      })
    } else {
      // Post a new message if messageID does not exist
      dispatch(postMessageToSegment(messageData)).then(() => {
        navigate('/messagecampaigns')
      })
    }
  }

  const onDateTimeChange = (newDateTime) => {
    // Make sure newDateTime is a Moment object before calling format or toDate
    if (moment.isMoment(newDateTime)) {
      if (newDateTime.toDate() < new Date()) {
        customToast.error('You cannot schedule a message in the past.')
        onChange({ dateTime: new Date() })
      } else {
        onChange({ dateTime: newDateTime.toDate() })
      }
    } else {
      // If newDateTime is not a Moment object, try to parse it as a date string
      const parsedDate = moment(newDateTime, 'YYYY-MM-DD')
      if (parsedDate.isValid()) {
        onDateTimeChange(parsedDate)
      } else {
        customToast.error('Invalid date format.')
      }
    }
  }

  return (
    <div className='mt-8'>
      <h1 className='font-bold text-xl md:text-2xl pb-4'> Campaign Summary </h1>
      <div className='border rounded-md p-8 bg-white'>
        {/* Render Subject Details */}
        <div className='flex flex-row justify-between items-center border-b pb-8'>
          <div className='flex flex-col items-start pl-0 md:pl-8 pt-8'>
            <h1 className='font-bold text-base md:text-lg pb-4'>Subject </h1>
            <div className='text-xs md:text-base draftjs-content text-left rich-text-content'>{renderRichText(messageSubject)}</div>
          </div>
          <div className='flex flex-col  pr-0 md:pr-8 pt-8'>
            <button className='btn text-xs md:text-base' onClick={handleEditMessage}>
              Edit
            </button>
          </div>
        </div>
        {/* Render Body details */}
        <div className='flex flex-row justify-between items-center pb-8 border-b '>
          <div className='flex flex-col items-start pl-0 md:pl-8 pt-8'>
            <h1 className='font-bold text-base md:text-lg pb-4 '>Body</h1>
            <div className='text-left' style={{ whiteSpace: 'pre-line' }}>
              <div className='text-xs md:text-base draftjs-content text-left rich-text-content'>{renderRichText(messageBody)}</div>
            </div>
          </div>
          <div className='flex flex-col pr-0 md:pr-8 pt-8'>
            <button className='btn text-xs md:text-base' onClick={handleEditMessage}>
              Edit
            </button>
          </div>
        </div>

        {/* Lists */}
        <div className='flex flex-row justify-between items-center pb-8 border-b '>
          <div className='flex flex-col items-start pl-0 md:pl-8 pt-8'>
            <h1 className='font-bold text-base md:text-lg pb-4'>List(s)</h1>
            {customerSegment.map((segment, index) => (
              <p className='text-xs md:text-base' key={index}>
                {segment.segmentName}
              </p>
            ))}
          </div>
          <div className='flex flex-col pr-0 md:pr-8 pt-8'>
            <button className='btn text-xs md:text-base' onClick={() => setCurrentStep(2)}>
              {' '}
              Edit
            </button>
          </div>
        </div>
        <div className='schedule-section my-4 flex flex-col md:flex-row items-center justify-center '>
          <div className='flex items-center md:mr-4'>
            <label htmlFor='schedule-toggle' className='mr-2 text-xs md:text-base'>
              Schedule Message:
            </label>
            <input type='checkbox' id='schedule-toggle' className='toggle toggle-success' checked={isScheduled} onChange={handleToggleSchedule} />
          </div>
          {isScheduled && (
            <div className='border p-2 rounded-md mt-5 md:mt-0  md:mb-0 w-[230px]'>
              <Datetime
                className='custom-datetime'
                value={moment(dateTime, 'ddd MMM DD YYYY HH:mm:ss')}
                onChange={onDateTimeChange}
                dateFormat='ddd MMM DD YYYY'
                timeFormat='HH:mm:ss'
                isValidDate={(currentDate) => currentDate.startOf('day').isSameOrAfter(moment().startOf('day'))}
              />
            </div>
          )}
        </div>
        {/* Render DateTime component only if isScheduled is true */}

        <div className='campaign-actions space-x-4 mt-12'>
          <button onClick={handleSendMessage} className='btn text-xs md:text-base btn-primary mb-4'>
            <FontAwesomeIcon icon={faPaperPlane} />
            <span className='ml-2'>{isScheduled ? 'Schedule Send' : 'Send Message'}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Summary
