export const Modal = ({ isOpen, onClose, children, title }) => {
  if (!isOpen) return null

  return (
    <div className='fixed  inset-0 z-50 overflow-y-auto  custom-zindex'>
      <div className='flex items-center justify-center min-h-screen px-0 py-4'>
        <div className='fixed inset-0 bg-black  opacity-30' onClick={onClose}></div>
        <div className='relative bg-white rounded-lg w-full mx-0 max-w-lg max-h-[90vh] flex flex-col'>
          <div className='sticky top-0 rounded-lg pt-2 bg-white z-[5] px-6 border-b shrink-0 flex justify-between items-start'>
            {title && <h2 className='font-bold text-2xl mb-4'>{title}</h2>}
            <button
              onClick={onClose}
              className='btn btn-sm btn-circle -mt-1 -mr-2 z-10 bg-black text-white hover:bg-gray-800 transition-transform scale-90 hover:scale-110'>
              ✕
            </button>
          </div>
          <div className='overflow-y-auto'>
            <div className='p-6 pt-4'>{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
