import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Spinner from '../components/Spinner'
import { getTEDSPackages, processTEDSPayment } from '../features/payments/paymentsSlice'
import { fetchTEDSWallet } from '../features/teds/tedsSlice'
import BackButton from '../components/BackButton'
import { Modal } from '../components/Modal'
import { getUserDetails } from '../features/auth/authSlice'
import { customToast } from '../components/CustomToast'
import UpdatePaymentModal from '../components/UpdatePaymentModal'

const PurchaseTEDSPackages = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { TEDSPackages = [], isLoading, isError, message } = useSelector((state) => state.payments)
  const { user } = useSelector((state) => state.auth)

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [selectedPackage, setSelectedPackage] = useState(null)
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  useEffect(() => {
    dispatch(getTEDSPackages())
    dispatch(getUserDetails())
    dispatch(fetchTEDSWallet())
  }, [dispatch])

  const handleBuyNow = async (pkg) => {
    if (!user?.subscription?.cardDetails?.id) {
      customToast.error('Please add a payment method in your subscription settings first')
      return
    }
    setSelectedPackage(pkg)
    setShowConfirmModal(true)
  }

  const handleConfirmPurchase = async () => {
    setIsProcessing(true)
    try {
      const paymentData = {
        subscriptionId: user.subscription._id,
        tedsPackage: {
          id: selectedPackage.id,
          teds: selectedPackage.teds,
          price: selectedPackage.price,
        },
      }

      await dispatch(processTEDSPayment(paymentData)).unwrap()
      setPaymentSuccess(true)
      dispatch(getUserDetails())
      dispatch(fetchTEDSWallet())
    } catch (error) {
      console.error('Payment error:', error)
      customToast.error(error.message || 'Payment failed')
    } finally {
      setIsProcessing(false)
    }
  }

  if (isError) return <p className='text-center text-red-500'>{message}</p>

  return (
    <>
      <div className='flex'>
        <BackButton />
      </div>
      <div className='mt-10 pb-20'>
        <h2 className='text-center text-3xl font-semibold mb-20'>Purchase TEDS Packages</h2>

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
          {Array.isArray(TEDSPackages) &&
            TEDSPackages.map((pkg) => (
              <div key={pkg.id} className='card bg-base-100 shadow-xl hover:shadow-2xl transition-all duration-300'>
                <div className='card-body text-center flex flex-col items-center p-8'>
                  <div className='relative mb-6'>
                    <img src='/images/TEDSLogo-sm.png' alt='TEDS Logo' className='w-32 h-32 object-contain drop-shadow-lg' />
                    <div className='absolute -inset-1 bg-gradient-to-r from-orange-400 to-amber-500 opacity-20 blur rounded-full'></div>
                  </div>

                  <h2 className='card-title text-4xl font-bold mb-2'>{pkg.teds} TEDS</h2>
                  <p className='text-3xl font-bold text-primary mb-3'>£{pkg.price}</p>
                  <p className='text-gray-600 mb-6'>
                    Required for when customers may wish to sell you back stamps. Purchase enough to cover your expected redemptions.
                  </p>

                  <div className='tooltip w-full' data-tip={`Purchase ${pkg.teds} TEDS for £${pkg.price}`}>
                    <button
                      className='btn btn-primary w-full text-lg  uppercase tracking-wider font-bold hover:scale-105 transition-transform'
                      onClick={() => handleBuyNow(pkg)}
                      disabled={isLoading || !user?.subscription?.cardDetails}>
                      {isLoading ? <span className='flex items-center gap-2'>Processing...</span> : 'Buy Now'}
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      {showConfirmModal && (
        <Modal isOpen={showConfirmModal} onClose={() => setShowConfirmModal(false)} title='Confirm Purchase'>
          {paymentSuccess ? (
            <div className='text-center py-8'>
              <div className='mb-4'>
                <svg className='mx-auto h-12 w-12 text-green-500' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M5 13l4 4L19 7' />
                </svg>
              </div>
              <h3 className='text-2xl font-bold text-green-600 mb-2'>Payment Successful!</h3>
              <p className='text-gray-600 px-8 mb-6'>
                Your TEDS Purchase was successful, please allow a few moments for your TEDS to be visible in your wallet.
              </p>
              <button
                className='btn btn-primary'
                onClick={() => {
                  setShowConfirmModal(false)
                  setSelectedPackage(null)
                  setPaymentSuccess(false)
                }}>
                Close
              </button>
            </div>
          ) : (
            <div className='p-6'>
              <p className='mb-6'>
                Purchase {selectedPackage.teds} TEDS for £{selectedPackage.price}
              </p>

              <div className='bg-gray-50 p-4 rounded-lg mb-6'>
                <div className='flex items-center gap-2'>
                  <img src={`/images/card-icons/visa.png`} alt={user.subscription.cardDetails.brand} className='h-6' />
                  <span className='text-gray-600'>**** **** **** {user.subscription.cardDetails.last4}</span>
                  <button
                    onClick={() => {
                      setShowUpdatePaymentModal(true)
                    }}
                    className='text-red-500 ml-auto hover:underline font-medium'>
                    EDIT
                  </button>
                </div>
              </div>

              <div className='flex justify-end gap-3'>
                <button className='btn btn-primary' onClick={handleConfirmPurchase} disabled={isProcessing}>
                  {isProcessing ? <span className='flex items-center gap-2'>Processing...</span> : 'Confirm Purchase'}
                </button>
              </div>
            </div>
          )}
        </Modal>
      )}

      {showUpdatePaymentModal && (
        <UpdatePaymentModal
          isOpen={showUpdatePaymentModal}
          onClose={() => setShowUpdatePaymentModal(false)}
          customerId={user.subscription.squareCustomerID}
        />
      )}
    </>
  )
}

export default PurchaseTEDSPackages
