import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { updateGoogleReviewSettings, getGoogleReviewSettings, reset } from '../features/profile/profileSlice'
import { customToast } from './CustomToast'

const GoogleReviews = () => {
  const { isLoading, isSuccess, isError, message, googleReviewSettings } = useSelector((state) => state.profile)
  const [formData, setFormData] = useState({
    googleMapsLink: '',
    requestReviewAfter: '5',
  })

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getGoogleReviewSettings())
  }, [dispatch])

  useEffect(() => {
    if (googleReviewSettings) {
      setFormData({
        googleMapsLink: googleReviewSettings.googleMaps?.googleMapsLink || '',
        requestReviewAfter: googleReviewSettings.googleMaps?.requestReviewAfter || '1',
      })
    }
  }, [googleReviewSettings])

  useEffect(() => {
    if (isSuccess) {
      customToast.success(message)
    }
    if (isError) {
      customToast.error(message || 'An error occurred while updating settings.')
    }

    dispatch(reset())
  }, [isSuccess, isError, message, dispatch])

  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(updateGoogleReviewSettings(formData))
  }

  const onChange = (e) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const tooltipText = `To get your Google review link:
  1. Go to Google Maps and search for your business
  2. Click on your business name
  3. Scroll to 'Reviews' and click 'Write a review'
  4. Copy the URL from your browser's address bar`

  return (
    <div className='flex flex-col w-full justify-between items-left border p-4 rounded-md mt-8 bg-gray-50'>
      <h1 className='font-bold text-xl md:text-2xl md:text-left'>Google Reviews</h1>
      <div className='divider'></div>

      <div role='alert' className='alert alert-info mb-6 justify-start'>
        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' className='h-6 w-6 shrink-0 stroke-current mr-2'>
          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
        </svg>
        <span className='text-left'>
          Supercharge your online reputation with smart Google Reviews settings! This feature is designed to showcase your best customer experiences.
          When happy customers rate you 4 or 5 stars, they're seamlessly guided to leave glowing Google reviews, boosting your visibility and
          attracting more business. For other ratings, you'll receive valuable private feedback, helping you continuously improve without impacting
          your public profile. It's a win-win: you gain insightful feedback and amplify your positive reviews, all while building a stellar online
          presence. Start collecting those 5-star reviews and watch your business shine!
        </span>
      </div>
      <form onSubmit={onSubmit}>
        <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
          <label className='form-label'>GOOGLE MAPS LINK</label>
          <div className='flex flex-row w-full'>
            <input
              type='text'
              className='form-input-profile'
              placeholder='https://search.google.com/local/writereview?placeid=...'
              id='googleMapsLink'
              name='googleMapsLink'
              value={formData.googleMapsLink}
              onChange={onChange}
            />
            <div className='campaign-tooltip' data-tip={tooltipText}>
              <AiOutlineInfoCircle />
            </div>
          </div>
        </div>

        <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
          <label className='form-label'>REQUEST REVIEW AFTER</label>
          <div className='flex flex-row w-full'>
            <select
              className='form-input-profile'
              id='requestReviewAfter'
              name='requestReviewAfter'
              value={formData.requestReviewAfter}
              onChange={onChange}>
              {[...Array(5)].map((_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1} customer visits
                </option>
              ))}
            </select>
            <div
              className='campaign-tooltip'
              data-tip='Customers will be asked to rate the service out of 5 stars. Only if they pick 4 or 5 stars will they be taken to the Google review. Otherwise, they will have the option to leave feedback which will only go to you as the admin. The review link will no longer show after feedback has been given.'>
              <AiOutlineInfoCircle />
            </div>
          </div>
        </div>
        <button className='btn btn-sm flex mt-8' disabled={isLoading}>
          {isLoading ? (
            <>
              <img className='' src='/images/loadingSpinner.svg' alt='Loading...' />
              <span>Saving...</span>
            </>
          ) : (
            <span>Save Changes</span>
          )}
        </button>
      </form>
    </div>
  )
}

export default GoogleReviews
