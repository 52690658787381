import { useState, useEffect } from 'react'
import { updatePostcodeSectors, getPostcodeSectors, reset } from '../features/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import { customToast } from './CustomToast'

const validatePostCode = (postcode) => {
  const regex = new RegExp(/^(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKS-UW]) ?[0-9][ABD-HJLNP-UW-Z]{2})$/i)
  return regex.test(postcode)
}

function formatPostcode(p) {
  var cleanedPostcode = p.replace(/\s+/g, '').toUpperCase()
  var postcodeRegEx = /^([A-Z]{1,2}[0-9][A-Z0-9]?)([0-9][A-Z]{2})$/i
  return cleanedPostcode.replace(postcodeRegEx, function (match, part1, part2) {
    return part1 + ' ' + part2
  })
}

const PostcodeSectors = () => {
  const { isLoading, isSuccess, isError, message, postcodeSectors } = useSelector((state) => state.auth)
  const [formData, setFormData] = useState({
    postcode: '',
    additionalPostcode1: '',
    additionalPostcode2: '',
  })

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPostcodeSectors())
  }, [dispatch])

  useEffect(() => {
    if (postcodeSectors) {
      setFormData((prevState) => ({
        ...prevState,
        postcode: postcodeSectors[0] || prevState.postcode,
        additionalPostcode1: postcodeSectors[1] || prevState.additionalPostcode1,
        additionalPostcode2: postcodeSectors[2] || prevState.additionalPostcode2,
      }))
    }
  }, [postcodeSectors])

  const onSubmit = (e) => {
    e.preventDefault()

    const { additionalPostcode1, additionalPostcode2 } = formData

    // Get the first 3/4 of the main postcode
    const mainPostcodePrefix = formData.postcode.replace(/\s/g, '').slice(0, -3)

    if (additionalPostcode1) {
      if (!validatePostCode(additionalPostcode1)) {
        customToast.error('Invalid postcode format for Additional Postcode 1')
        return
      }

      if (!additionalPostcode1.startsWith(mainPostcodePrefix)) {
        customToast.error(`Additional Postcode 1 must start with the same prefix as your main postcode (${formData.postcode})`)
        return
      }
    }

    if (additionalPostcode2) {
      if (!validatePostCode(additionalPostcode2)) {
        customToast.error('Invalid postcode format for Additional Postcode 2')
        return
      }

      if (!additionalPostcode2.startsWith(mainPostcodePrefix)) {
        customToast.error(`Additional Postcode 2 must start with the same prefix as your main postcode (${formData.postcode})`)
        return
      }
    }

    const userData = {
      additionalPostcode1,
      additionalPostcode2,
    }

    dispatch(updatePostcodeSectors(userData))
  }

  const onChange = (e) => {
    const { name, value } = e.target
    let formattedValue = value

    if (name === 'additionalPostcode1' || name === 'additionalPostcode2') {
      // Remove any non-alphanumeric characters except for spaces
      formattedValue = value.replace(/[^a-zA-Z0-9\s]/g, '')

      // Limit the input to a maximum of 8 characters (including spaces)
      formattedValue = formattedValue.slice(0, 8)

      // Format the postcode
      formattedValue = formatPostcode(formattedValue)
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: formattedValue,
    }))
  }

  return (
    <div className='flex flex-col w-full justify-between items-left border p-4 rounded-md mt-8 bg-gray-50'>
      <h1 className='font-bold text-xl md:text-2xl md:text-left'>Postcode Exclusivity Sectors</h1>
      <div className='divider'></div>

      {/* Add the alert here */}
      <div role='alert' className='alert alert-info mb-6 justify-start'>
        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' className='h-6 w-6 shrink-0 stroke-current mr-2'>
          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
        </svg>
        <span className='text-left'>
          You have exclusivity in your primary postcode. Add up to two additional postcodes to expand your exclusive area. No other business of your
          type can use our system within these postcodes.
        </span>
      </div>

      <form onSubmit={onSubmit}>
        {/* Actual Postcode */}
        <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
          <label className='form-label'>YOUR POSTCODE</label>
          <input
            type='text'
            className='form-input-profile bg-gray-200'
            placeholder='Enter your actual postcode'
            id='postcode'
            name='postcode'
            value={formData.postcode}
            onChange={onChange}
            disabled
          />
        </div>

        {/* Additional Postcode 1 */}
        <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
          <label className='form-label'>ADDITIONAL POSTCODE 1</label>
          <input
            type='text'
            className='form-input-profile'
            placeholder='Enter an additional postcode'
            id='additionalPostcode1'
            name='additionalPostcode1'
            value={formData.additionalPostcode1}
            onChange={onChange}
            maxLength={8}
          />
        </div>

        {/* Additional Postcode 2 */}
        <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 mt-4 mr-3 lg:mr-10 xl:mr-60 2xl:mr-96'>
          <label className='form-label'>ADDITIONAL POSTCODE 2</label>
          <input
            type='text'
            className='form-input-profile'
            placeholder='Enter another additional postcode'
            id='additionalPostcode2'
            name='additionalPostcode2'
            value={formData.additionalPostcode2}
            onChange={onChange}
            maxLength={8}
          />
        </div>

        <button className='btn btn-sm flex mt-8' disabled={isLoading}>
          {isLoading ? (
            <>
              <img className='' src='/images/loadingSpinner.svg' alt='Loading...' />
              <span>Saving...</span>
            </>
          ) : (
            <span>Save Changes</span>
          )}
        </button>
      </form>
    </div>
  )
}

export default PostcodeSectors
